<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <slot name="breadcrumb">
      <app-breadcrumb :change_panel="change_panel" />
    </slot>

    <b-card
      @mouseleave="handleToggle"
      style="
        position: fixed;
        right: 0;
        z-index: 9998;
        height: 100vh;
        top: 7.8rem;
        width: 27rem;
      "
      v-if="phone_panel_toggle && !pin_soft_phone"
      class="pt-0 pb-0 px-0 slide-in mb-0 shadow-sm"
      no-body
    >
      <!-- SWITCH -->
      <div class="d-flex align-items-center mx-1 my-1 border-bottom pb-1">
        <div class="d-flex align-items-center">
          <b-img class="mr-50" :src="logo_sq" fluid style="width: 1rem"></b-img>
          <span class="fade-in-effect" v-show="!show_chat">Dialpad</span>
          <span class="fade-in-effect" v-show="show_chat">Chat</span>
        </div>

        <div class="d-flex ml-auto align-items-center">
          <b-button
            v-if="
              current_call_info &&
              other_permission.includes('real_time_transcript')
            "
            class="py-50 px-50"
            variant="flat-primary"
            @click="show_real_time_transcript = !show_real_time_transcript"
          >
            <i class="bi bi-chat-square-text"></i>
          </b-button>
          <b-form-checkbox
            v-model="show_chat"
            class="v-custom-control-primary ml-1"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <i class="bi bi-telephone text-light"></i>
            </span>
            <span class="switch-icon-right">
              <i class="bi bi-chat text-light"></i>
            </span>
          </b-form-checkbox>
          <b-button
            @click="
              () => {
                pin_soft_phone = !pin_soft_phone;
                phone_panel_toggle = true;
              }
            "
            pill
            variant="flat-secondary"
            class="py-0 px-0 ml-50 no-hover border-0"
          >
            <i
              :class="['bi', pin_soft_phone ? 'bi-pin-fill' : 'bi-pin-angle']"
            ></i>
          </b-button>
        </div>
      </div>
      <div
        style="height: 100% !important"
        class="slide-in"
        v-show="
          show_real_time_transcript &&
          current_call_info &&
          current_call_info.Extra
        "
      >
        <div>
          <div class="chat-panel-container">
            <div class="chat_panel_inner">
              <b-container class="bg-primary mb-1 mt-1" fluid>
                <b-row align-h="between" align-v="center">
                  <b-button
                    size="sm"
                    variant="primary"
                    class="btn-rounded"
                    @click="show_real_time_transcript = false"
                  >
                    <feather-icon
                      class="light-text"
                      size="14"
                      icon="ArrowLeftIcon"
                    />
                  </b-button>
                  <b-badge
                    variant="primary"
                    v-if="
                      current_call_info &&
                      current_call_info.Extra &&
                      current_call_info.Extra.RemoteNumber
                    "
                  >
                    {{ current_call_info.Extra.RemoteNumber }}</b-badge
                  >
                </b-row>
              </b-container>

              <div
                id="msg_div_real_time"
                class="messages"
                v-if="current_call_info"
              >
                <div
                  class="msg"
                  v-for="(value, key) in current_call_info.speech"
                  :key="key"
                >
                  <span :class="value.Side == 'Agent' ? 'msg_to' : 'msg_from'"
                    >{{ value.Text }}
                    <!-- <small class="time"> {{ msgTime(value.Time) }} </small> -->
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SOFTPHONE -->
      <div
        class="slide-in"
        v-show="!show_chat && qm_socket_connected && !show_real_time_transcript"
      >
        <div
          v-if="!qm_socket_connected"
          class="d-flex align-items-center justify-content-center full-height"
          style="margin: 0"
        >
          <div
            class="d-flex align-items-center justify-content-center full-height"
          >
            <b-spinner variant="primary" label="" />
            <span class="ml-1">Connecting...</span>
          </div>
        </div>

        <!-- ÇALIŞMA ALANI -->

        <div
          v-if="phone_panel_toggle && qm_socket_connected"
          style="height: 95vh; overflow-y: auto"
        >
          <b-card-body>
            <b-card-text :class="[dynamicMargin, 'mb-3', dynamicMarginTop]">
              <b-row>
                <b-col>
                  <div class="d-flex align-items-center">
                    <div class="d-inline-block">
                      <span>
                        <b-button
                          squared
                          :variant="
                            selected_line == 1 ? 'primary' : 'flat-secondary'
                          "
                          class="py-25 px-50"
                          size="sm"
                          @click="change_line(1)"
                        >
                          1
                        </b-button>
                        <b-button
                          squared
                          class="py-25 px-50 text-muted"
                          :variant="
                            selected_line == 2 ? 'primary' : 'flat-secondary'
                          "
                          size="sm"
                          @click="change_line(2)"
                          v-if="other_permission.includes('second_line')"
                        >
                          2
                        </b-button>
                      </span>
                    </div>
                    <b-input-group class="shadow-none" :style="buttonStyle">
                      <b-form-input
                        @focus="isInputFocused = true"
                        @blur="isInputFocused = false"
                        ref="contact_number"
                        size="lg"
                        name="contact_number"
                        :readonly="interaction[selected_line] != null"
                        v-on:keydown.enter.prevent="call_request"
                        class="text-center font-weight-bolder font-medium-5 border-0 shadow-none rounded"
                        v-model="contact_number"
                        :style="buttonStyle"
                      >
                      </b-form-input>
                      <b-input-group-append
                        class="d-flex justify-content-center align-items-center"
                      >
                        <span
                          v-if="call_timer > 0"
                          class="text-center font-weight-lighter mr-1"
                          >{{ call_timer_text }}</span
                        >
                        <span v-else class="text-center font-weight-lighter">{{
                          last_call_event
                        }}</span>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="interaction['1'] != null" class="d-inline-block">
                      <span>
                        <b-button
                          v-b-tooltip.hover.v-secondary
                          v-b-tooltip.hover.bottom="lang('t_finishCode')"
                          v-b-tooltip="{ customClass: 'cZIndex' }"
                          squared
                          class="py-50 px-50"
                          :variant="'flat-primary'"
                          size="sm"
                          @click="modal_finish_code_copy = true"
                        >
                          <i class="bi bi-check-circle"></i>
                        </b-button>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex align-items-center ml-3">
                    <!-- Queue -->
                    <b-dropdown
                      toggle-class="text-decoration-none bg-transparent pl-0"
                      no-caret
                      menu-class="d-menu-class font-small-3 py-0"
                      size="sm"
                      variant="link"
                      class="bg-transparent"
                      @hidden="
                        queueFilterSearch = '';
                        classificationFilterSearch = '';
                      "
                    >
                      <template #button-content>
                        <span
                          class="d-inline-block text-truncate text-secondary"
                          style="max-width: 7rem"
                        >
                          {{
                            queues.find(
                              (e) => e.internal_name == selected_queue
                            )
                              ? queues.find(
                                  (e) => e.internal_name == selected_queue
                                ).display_name
                              : selected_queue
                          }}
                        </span>
                      </template>
                      <b-form-input
                        v-model="queueFilterSearch"
                        size="sm"
                        :placeholder="lang('t_search')"
                      ></b-form-input>
                      <b-dropdown-item
                        @click="onSelectedQueueChange(item)"
                        v-for="(item, i) in queueFiltered"
                        :key="i"
                      >
                        {{
                          queues.find((e) => e.internal_name == item)
                            ? queues.find((e) => e.internal_name == item)
                                .display_name
                            : item
                        }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <!-- Classification -->
                    <b-dropdown
                      toggle-class="text-decoration-none bg-transparent pl-0"
                      no-caret
                      menu-class="font-small-3 py-0"
                      size="sm"
                      v-if="selected_queue"
                      variant="link"
                      class="bg-transparent"
                      @hidden="
                        queueFilterSearch = '';
                        classificationFilterSearch = '';
                      "
                    >
                      <template #button-content>
                        <div class="d-flex align-items-center">
                          <span class="text-secondary">{{
                            selected_dial_plan
                          }}</span>
                        </div>
                      </template>

                      <b-form-input
                        v-model="classificationFilterSearch"
                        size="sm"
                        :placeholder="lang('t_search')"
                      ></b-form-input>

                      <b-dropdown-item
                        @click="
                          () => {
                            selected_dial_plan = item.name;
                            is_international_call =
                              item.international_call || false;
                          }
                        "
                        v-for="(item, i) in classificationFiltered"
                        :key="i"
                      >
                        {{ `${item.name}` }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>

            <!-- 123 -->
            <b-card-text :class="[dynamicMargin, 'my-2']">
              <b-row>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('1')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">1</h3>
                      <span class="font-small-1"></span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('2')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">2</h3>
                      <span class="font-small-1">ABC</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('3')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">3</h3>
                      <span class="font-small-1">DEF</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>

            <!-- 456 -->
            <b-card-text :class="[dynamicMargin, 'my-2']">
              <b-row>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('4')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">4</h3>
                      <span class="font-small-1">GHI</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('5')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">5</h3>
                      <span class="font-small-1">JKL</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('6')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">6</h3>
                      <span class="font-small-1">MNO</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>

            <!-- 789 -->
            <b-card-text :class="[dynamicMargin, 'my-2']">
              <b-row>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('7')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">7</h3>
                      <span class="font-small-1">PQRS</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('8')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">8</h3>
                      <span class="font-small-1">TUV</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('9')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">9</h3>
                      <span class="font-small-1">WXYZ</span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>

            <!-- *0# -->
            <b-card-text :class="[dynamicMargin, 'my-2']">
              <b-row>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('*')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">✻</h3>
                      <span class="font-small-1"></span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('0')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">0</h3>
                      <span class="font-small-1">+</span>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    @click="write_phone('#')"
                    class="btn-icon rounded-circle"
                    variant="flat-secondary"
                  >
                    <div
                      class="text-center align-items-center align-items-center"
                      :style="{ width: '3rem', height: '3rem' }"
                    >
                      <h3 class="mb-0 font-weight-bolder">#</h3>
                      <span class="font-small-1"></span>
                    </div>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>

            <!-- CALL -->
            <b-card-text :class="[dynamicMargin, 'mt-3']">
              <b-row>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                  <b-button
                    v-if="interaction[selected_line] != null"
                    @click="hangup"
                    :class="['btn-icon', 'rounded-circle']"
                    variant="danger"
                  >
                    <div
                      :style="{ width: '3rem', height: '3rem' }"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <i class="bi bi-x-lg font-medium-5"></i>
                    </div>
                  </b-button>
                  <b-button
                    v-else
                    @click="call_request"
                    :class="['btn-icon', 'rounded-circle']"
                    variant="success"
                  >
                    <div
                      :style="{ width: '3rem', height: '3rem' }"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <i class="bi bi-telephone-fill font-medium-5"></i>
                    </div>
                  </b-button>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex justify-content-center align-items-center"
                >
                </b-col>
              </b-row>
            </b-card-text>
            <div :class="[dynamicMargin]" class="pt-3">
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  v-if="other_permission.includes('agent_list')"
                  @click="modal_agent_list = true"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="lang('t_agentList')"
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i class="bi bi-people font-medium-3"></i>
                </b-button>
                <b-button
                  @click="mute"
                  v-if="interaction[selected_line] != null"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isMuted().audio
                        ? lang('t_microphoneOn')
                        : lang('t_microphoneOff')
                      : lang('t_microphoneOff')
                  "
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i
                    class="bi bi-mic-mute font-medium-3"
                    :class="
                      interaction[selected_line] != null
                        ? interaction[selected_line].isMuted().audio
                          ? 'bi-mic'
                          : 'bi-mic-mute'
                        : 'bi-mic-mute'
                    "
                  ></i>
                </b-button>
                <b-button
                  v-if="interaction[selected_line] != null"
                  @click="hold"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  v-b-tooltip.hover.top="
                    interaction[selected_line] != null
                      ? interaction[selected_line].isOnHold().local
                        ? lang('t_continue')
                        : lang('t_hold')
                      : lang('t_hold')
                  "
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i
                    class="bi font-medium-3"
                    :class="
                      interaction[selected_line] != null
                        ? interaction[selected_line].isOnHold().local
                          ? 'bi-play'
                          : 'bi-pause'
                        : 'bi-pause'
                    "
                  ></i>
                </b-button>
                <b-button
                  v-if="
                    selected_line != 2 && interaction[selected_line] != null
                  "
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  v-b-tooltip.hover.top="lang('t_transfer')"
                  @click="open_transfer_modal"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i class="bi bi-telephone-forward font-medium-3"></i>
                </b-button>
                <b-button
                  v-if="
                    selected_line != 2 && interaction[selected_line] != null
                  "
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  v-b-tooltip.hover.top="lang('t_conference')"
                  @click="openConferenceModal"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i class="bi bi-person-add font-medium-3"></i>
                </b-button>
                <b-button
                  v-if="
                    !other_permission.includes('dont_change_survey') &&
                    selected_line != 2 &&
                    interaction[selected_line] != null &&
                    dtmf_list.find((e) => e.includes('Anket'))
                  "
                  :disabled="interaction[selected_line] == null"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  v-b-tooltip.hover.top="
                    dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                      ? lang('t_surveyCancel')
                      : lang('t_surveyApprove')
                  "
                  @click="set_survey_state"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i
                    class="bi font-medium-3"
                    :class="
                      dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                        ? 'bi-clipboard2-x'
                        : 'bi-clipboard-check'
                    "
                  ></i>
                </b-button>
                <b-button
                  v-if="
                    dtmf_list.length > 0 &&
                    other_permission.includes('show_ivr_dial')
                  "
                  :disabled="interaction[selected_line] == null"
                  @click="modal_dtmf_list = true"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.top="lang('t_showInputs')"
                  v-b-tooltip="{ customClass: 'cZIndex' }"
                  :class="['btn-icon']"
                  variant="flat-secondary"
                >
                  <i class="bi bi-eye font-medium-3"></i>
                </b-button>
              </div>
            </div>
          </b-card-body>
          <!-- CALL ACTION-->
        </div>

        <!-- ÇALIŞMA ALANI -->

        <b-card
          v-if="chat_panel_toggle && qm_socket_connected"
          no-body
          style="max-heigth: 100%; height: 100%; margin: 0"
          class="ma-0"
        >
          <div>
            <b-tabs fill v-if="chatpanel == false">
              <b-tab :title="lang('t_conversations')">
                <!-- username != chat._id.messages[0].From
                        ? chat._id.messages[0].From
                        : chat._id.messages[0].To -->
                <div
                  v-for="(chat, i) in recent"
                  :key="i"
                  @click="continueNewChat(chat)"
                  class="cursor-pointer my-50 mx-50 employee-task d-flex justify-content-between align-items-center"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar
                        class="badge-glow"
                        :variant="getChatColor(chat)"
                        badge-variant="success"
                      >
                        {{
                          (username != chat._id.messages[0].From
                            ? chat._id.messages[0].From
                            : chat._id.messages[0].To
                          )
                            .charAt(0)
                            .toUpperCase()
                        }}
                        <template
                          #badge
                          v-if="
                            userUnRead.hasOwnProperty(
                              chat._id.messages[0].From
                            ) && userUnRead[chat._id.messages[0].From] > 0
                          "
                        >
                          {{ userUnRead[chat._id.messages[0].From] }}
                        </template>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        {{
                          username != chat._id.messages[0].From
                            ? chat._id.messages[0].From
                            : chat._id.messages[0].To
                        }}
                      </h6>
                      <small
                        style="
                          display: inline-block;
                          width: 8vw;
                          white-space: nowrap;
                          overflow: hidden !important;
                          text-overflow: ellipsis;
                        "
                        >{{ chat._id.messages[0].msg }}</small
                      >
                    </b-media-body>
                  </b-media>
                </div>
              </b-tab>
              <b-tab :title="lang('t_announcementHistory')">
                <div style="max-height: 70vh; overflow-y: auto">
                  <div v-for="(item, i) in announcements" :key="i">
                    <b-card
                      no-body
                      border-variant="primary"
                      class="mx-50 mb-50"
                    >
                      <b-card-text
                        @click="showAnnounceDetails(item)"
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="lang('t_showDetail')"
                        class="cursor-pointer"
                      >
                        <div class="mx-50">
                          <small class="font-weight-bolder">
                            {{
                              item.sender.includes("@")
                                ? item.sender.split("@")[0]
                                : item.sender
                            }}
                            -
                            {{ msgTime(item.announcements.Timestamp) }}
                          </small>
                        </div>
                        <div class="mx-50">
                          <small
                            style="
                              display: inline-block;
                              width: 8vw;
                              white-space: nowrap;
                              overflow: hidden !important;
                              text-overflow: ellipsis;
                            "
                            >{{ item.announcements.msg }}</small
                          >
                        </div>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div class="mt-3 chat-panel-container" v-else>
              <div class="chat_panel_inner">
                <b-container class="bg-primary mb-1 mt-1" fluid>
                  <b-row align-h="between" align-v="center">
                    <b-button
                      size="sm"
                      variant="primary"
                      class="btn-rounded"
                      @click="closeChatPanel()"
                    >
                      <feather-icon
                        class="light-text"
                        size="14"
                        icon="ArrowLeftIcon"
                      />
                    </b-button>
                    <b-badge variant="primary">
                      {{
                        active_chat.username.includes("@")
                          ? active_chat.username.split("@")[0]
                          : active_chat.username
                      }}</b-badge
                    >
                  </b-row>
                </b-container>

                <div id="msg_div" class="messages">
                  <div
                    class="msg"
                    v-for="(value, key) in active_chat.messages"
                    :key="key"
                  >
                    <span
                      :class="
                        value.to == active_chat.username ? 'msg_to' : 'msg_from'
                      "
                      >{{ value.message }}
                      <small class="time"> {{ msgTime(value.time) }} </small>
                    </span>
                  </div>
                </div>
                <div class="mt-1">
                  <b-input-group>
                    <b-form-input
                      ref="msg"
                      v-model="message"
                      @keyup.enter="sendMsg()"
                      :placeholder="lang('t_yourMessage')"
                    ></b-form-input>
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="SendIcon"
                        class="cursor-pointer"
                        @click="sendMsg"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <!-- CHAT -->
      <div
        class="slide-in"
        v-show="show_chat && qm_socket_connected && !show_real_time_transcript"
      >
        <div
          style="max-heigth: 100%; height: 100%; margin: 0; overlow-y: auto"
          class="ma-0"
        >
          <div v-if="chatpanel == false">
            <!-- SEARCH AND START NEW CHAT -->
            <div class="mx-1 d-flex align-items-center mb-1 slide-in">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  @focus="isInputFocused = true"
                  @blur="isInputFocused = false"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>
              <b-button
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_announcementHistory')"
                v-b-tooltip="{ customClass: 'cZIndex' }"
                variant="flat-secondary"
                class="btn-icon rounded-circle py-50 px-50 ml-50"
                v-if="!panels.new_chat && !showAnnoucementHistory"
                @click="showAnnoucementHistory = true"
              >
                <i class="bi bi-clock-history font-medium-1"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_startChat')"
                v-b-tooltip="{ customClass: 'cZIndex' }"
                variant="flat-secondary"
                class="btn-icon rounded-circle py-50 px-50 ml-50"
                @click="onNewChatClick"
                v-if="!panels.new_chat && !showAnnoucementHistory"
              >
                <feather-icon
                  class="pa-0 ma-0"
                  size="12"
                  icon="PlusIcon"
                ></feather-icon>
              </b-button>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle py-50 px-50 ml-50"
                @click="
                  () => {
                    panels.new_chat = false;
                    showAnnoucementHistory = false;
                  }
                "
                v-if="panels.new_chat == true || showAnnoucementHistory == true"
              >
                <feather-icon
                  class="pa-0 ma-0"
                  size="12"
                  icon="XIcon"
                ></feather-icon>
              </b-button>
            </div>
            <!-- CONVERSATIONS -->
            <div class="slide-in" v-if="showAnnoucementHistory == true">
              <div
                v-for="(item, i) in announcements"
                :key="i"
                class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
                @click="showAnnounceDetails(item)"
              >
                <b-avatar :variant="'light-secondary'" class="mr-1">
                  <i class="bi bi-megaphone font-medium-1"></i>
                </b-avatar>
                <div class="align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <span :class="true ? 'font-weight-bold' : ''">
                      {{ item.sender.split("@")[0] }}
                    </span>
                    <span
                      :class="true ? 'font-weight-bold' : ''"
                      class="text-muted ml-auto font-small-2"
                      >{{
                        new Date(
                          item.announcements.Timestamp
                        ).toLocaleTimeString(
                          "tr-TR",
                          (options = {
                            hour: "numeric",
                            minute: "2-digit",
                          })
                        )
                      }}</span
                    >
                  </div>
                  <div class="d-flex align-items-center">
                    <span
                      :class="true ? 'font-weight-bold' : ''"
                      class="text-muted text-truncate"
                      style="width: 12vw"
                    >
                      {{ item.announcements.msg }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!panels.new_chat">
              <div
                @click="continueNewChat(chat)"
                v-for="(chat, i) in recent"
                :key="i"
                class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
              >
                <b-avatar
                  :text="getConversationLetter(chat)"
                  :variant="getChatColor(chat)"
                  class="mr-1"
                  badge-variant="success"
                >
                  <template
                    #badge
                    v-if="
                      userUnRead.hasOwnProperty(chat._id.messages[0].From) &&
                      userUnRead[chat._id.messages[0].From] > 0
                    "
                  >
                  </template>
                </b-avatar>
                <div class="align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <span :class="true ? 'font-weight-bold' : ''">
                      {{
                        username != chat._id.messages[0].From
                          ? chat._id.messages[0].From.split("@")[0]
                          : chat._id.messages[0].To.split("@")[0]
                      }}
                    </span>
                    <span
                      :class="true ? 'font-weight-bold' : ''"
                      class="text-muted ml-auto font-small-2"
                      >{{
                        new Date(
                          chat._id.messages[0].Timestamp
                        ).toLocaleTimeString(
                          "tr-TR",
                          (options = {
                            hour: "numeric",
                            minute: "2-digit",
                          })
                        )
                      }}</span
                    >
                  </div>
                  <div class="d-flex align-items-center">
                    <span
                      :class="true ? 'font-weight-bold' : ''"
                      class="text-muted text-truncate"
                      style="width: 12vw"
                    >
                      {{ chat._id.messages[0].msg }}
                    </span>
                    <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-in" v-else>
              <div
                @click="startNewChat(chat.nickname)"
                v-for="(chat, i) in chat_users"
                :key="i"
                class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
              >
                <b-avatar
                  :text="getConversationLetter2(chat)"
                  :variant="'light-secondary'"
                  class="mr-1"
                >
                </b-avatar>
                <div class="align-items-center w-100">
                  <div class="d-flex align-items-center">
                    <span :class="true ? 'font-weight-bold' : ''">
                      {{ chat.nickname.split("@")[0] }}
                    </span>
                    <!-- <span
                    :class="true ? 'font-weight-bold' : ''"
                    class="text-muted ml-auto font-small-2"
                    >{{
                      new Date(
                        chat._id.messages[0].Timestamp
                      ).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}</span
                  > -->
                  </div>
                  <div class="d-flex align-items-center">
                    <span
                      :class="true ? 'font-weight-bold' : ''"
                      class="text-muted text-truncate"
                      style="width: 12vw"
                    >
                      {{ "Konuşma başlatmak için tıklayın.." }}
                    </span>
                    <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slide-in" v-else>
            <b-card no-body class="mb-0 rounded-0">
              <div class="border-bottom d-flex align-items-center px-1 py-1">
                <b-button
                  @click="closeChatPanel()"
                  pill
                  class="mr-50 py-50 px-50"
                  variant="flat-secondary"
                >
                  <i class="bi bi-arrow-left font-medium-1"></i>
                </b-button>
                <b-avatar
                  variant="light-secondary"
                  :text="getChatLetter()"
                  class="mr-1"
                ></b-avatar>
                <span>
                  {{
                    active_chat.username.includes("@")
                      ? active_chat.username.split("@")[0]
                      : active_chat.username
                  }}
                </span>
                <div class="ml-auto d-flex align-items-center">
                  <b-button
                    pill
                    class="ml-auto py-50 px-50 mx-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-search font-medium-1"></i>
                  </b-button>
                  <b-dropdown
                    variant="flat-secondary"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                    toggle-class="px-50 py-50 rounded-circle"
                    menu-class="d-menu-class"
                  >
                    <template #button-content>
                      <i class="bi bi-three-dots-vertical font-medium-1"></i>
                    </template>
                  </b-dropdown>
                </div>
              </div>
            </b-card>

            <div
              :style="{
                backgroundImage: 'url(' + chatBackgroundImage + ')',
              }"
              class="chat-bg fade-in-effect"
            >
              <div
                id="msg_div"
                class="messages"
                style="height: 46rem; overflow-y: auto"
              >
                <div
                  class="msg d-inline-block my-0 py-0"
                  v-for="(value, key) in active_chat.messages"
                  :key="key"
                >
                  <div
                    class="d-block my-50"
                    v-if="isNewDay(active_chat.messages, key)"
                  >
                    <div class="date-line">
                      <small class="line"></small>
                      <b-badge class="mt-50" pill variant="light-secondary">
                        <span class="font-weight-lighter">
                          {{ formatDate(value.time) }}
                        </span>
                      </b-badge>
                      <small class="line"></small>
                    </div>
                  </div>
                  <span
                    class=""
                    :class="
                      value.to == active_chat.username
                        ? 'msg_to bg-primary text-white'
                        : isDark
                        ? 'msg_from bg-secondary text-white'
                        : 'msg_from bg-white text-dark'
                    "
                    >{{ value.message }}
                    <small class="time text-muted">
                      {{
                        new Date(value.time).toLocaleTimeString(
                          "tr-TR",
                          (options = {
                            hour: "numeric",
                            minute: "2-digit",
                          })
                        )
                      }}
                    </small>
                  </span>
                </div>
              </div>
              <div
                class="d-flex align-items-center px-1 py-1 bg-transparent border-top"
              >
                <b-button
                  disabled
                  pill
                  variant="flat-secondary"
                  class="py-50 px-50"
                >
                  <i
                    style="
                      -webkit-transform: rotate(30deg);
                      -moz-transform: rotate(30deg);
                      -ms-transform: rotate(30deg);
                      -o-transform: rotate(30deg);
                      transform: rotate(30deg);
                      display: inline-block;
                    "
                    class="bi bi-paperclip font-medium-2"
                  ></i>
                </b-button>
                <b-button
                  pill
                  variant="flat-secondary"
                  class="py-50 px-50 mr-1"
                  id="note-popover-reactive-1"
                  ref="button2"
                >
                  <i class="bi bi-bookmark-plus"></i>
                </b-button>
                <b-form-input
                  @focus="isInputFocused = true"
                  @blur="isInputFocused = false"
                  @keyup.enter="sendMsg()"
                  ref="msg"
                  v-model="message"
                  :placeholder="lang('t_yourMessage')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-row>
      <div
        v-if="showContactNumber"
        class="fade-in-effect position-absolute px-3 text-center"
        style="
          z-index: 9998;
          top: 10%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <span class="font-large-3 font-weight-bolder">{{
          contact_number
        }}</span>
      </div>
      <b-col class="pt-0 pb-0">
        <div class="content-body">
          <transition :name="routerTransition" mode="out-in">
            <slot />
          </transition>
        </div>
      </b-col>
      <!-- <transition :name="'fade'" mode="fade"> -->
      <div
        v-if="!pin_soft_phone"
        style="
          position: fixed;
          width: 1rem;
          top: 7.8rem;
          height: 100vh;
          right: 0;
        "
        @mouseenter="onPhonePanelHover"
      ></div>
      <b-col v-else cols="3">
        <b-card
          v-if="phone_panel_toggle"
          class="pt-0 pb-0 px-0 mb-0 mb-0"
          no-body
          style="height: 57rem; overflow-y: auto"
        >
          <!-- SWITCH -->
          <div class="d-flex align-items-center mx-1 my-1 border-bottom pb-1">
            <div class="d-flex align-items-center">
              <b-img
                class="mr-50"
                :src="logo_sq"
                fluid
                style="width: 1rem"
              ></b-img>
              <span class="fade-in-effect" v-show="!show_chat">Dialpad</span>
              <span class="fade-in-effect" v-show="show_chat">Chat</span>
            </div>

            <div class="d-flex ml-auto align-items-center">
              <b-button
                v-if="
                  current_call_info &&
                  other_permission.includes('real_time_transcript')
                "
                class="py-50 px-50"
                variant="flat-primary"
                @click="show_real_time_transcript = !show_real_time_transcript"
              >
                <i class="bi bi-chat-square-text"></i>
              </b-button>
              <b-form-checkbox
                v-model="show_chat"
                class="v-custom-control-primary ml-1"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <i class="bi bi-telephone text-light"></i>
                </span>
                <span class="switch-icon-right">
                  <i class="bi bi-chat text-light"></i>
                </span>
              </b-form-checkbox>
              <b-button
                @click="
                  () => {
                    pin_soft_phone = !pin_soft_phone;
                    phone_panel_toggle = true;
                  }
                "
                pill
                variant="flat-secondary"
                class="py-0 px-0 ml-50 no-hover border-0"
              >
                <i
                  :class="[
                    'bi',
                    pin_soft_phone ? 'bi-pin-fill' : 'bi-pin-angle',
                  ]"
                ></i>
              </b-button>
            </div>
          </div>
          <div
            style="height: 100% !important"
            class="slide-in"
            v-show="
              show_real_time_transcript &&
              current_call_info &&
              current_call_info.Extra
            "
          >
            <div>
              <div class="chat-panel-container">
                <div class="chat_panel_inner">
                  <b-container class="bg-primary mb-1 mt-1" fluid>
                    <b-row align-h="between" align-v="center">
                      <b-button
                        size="sm"
                        variant="primary"
                        class="btn-rounded"
                        @click="show_real_time_transcript = false"
                      >
                        <feather-icon
                          class="light-text"
                          size="14"
                          icon="ArrowLeftIcon"
                        />
                      </b-button>
                      <b-badge
                        variant="primary"
                        v-if="
                          current_call_info &&
                          current_call_info.Extra &&
                          current_call_info.Extra.RemoteNumber
                        "
                      >
                        {{ current_call_info.Extra.RemoteNumber }}</b-badge
                      >
                    </b-row>
                  </b-container>

                  <div
                    id="msg_div_real_time"
                    class="messages"
                    v-if="current_call_info"
                  >
                    <div
                      class="msg"
                      v-for="(value, key) in current_call_info.speech"
                      :key="key"
                    >
                      <span
                        :class="value.Side == 'Agent' ? 'msg_to' : 'msg_from'"
                        >{{ value.Text }}
                        <!-- <small class="time"> {{ msgTime(value.Time) }} </small> -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SOFTPHONE -->
          <div
            class="slide-in"
            v-show="
              !show_chat && qm_socket_connected && !show_real_time_transcript
            "
          >
            <div
              v-if="!qm_socket_connected"
              class="d-flex align-items-center justify-content-center full-height"
              style="margin: 0"
            >
              <div
                class="d-flex align-items-center justify-content-center full-height"
              >
                <b-spinner variant="primary" label="" />
                <span class="ml-1">Connecting...</span>
              </div>
            </div>

            <!-- ÇALIŞMA ALANI -->

            <div
              v-if="phone_panel_toggle && qm_socket_connected"
              style="height: 100%; overflow-y: auto"
            >
              <b-card-body>
                <b-card-text :class="[dynamicMargin, 'mb-3', dynamicMarginTop]">
                  <div class="d-flex">
                    <div class="d-inline-block align-items-center">
                      <span>
                        <b-button
                          squared
                          :variant="
                            selected_line == 1 ? 'primary' : 'flat-secondary'
                          "
                          class="py-25 px-50"
                          size="sm"
                          @click="change_line(1)"
                        >
                          1
                        </b-button>
                        <b-button
                          squared
                          class="py-25 px-50 text-muted"
                          :variant="
                            selected_line == 2 ? 'primary' : 'flat-secondary'
                          "
                          size="sm"
                          @click="change_line(2)"
                          v-if="other_permission.includes('second_line')"
                        >
                          2
                        </b-button>
                      </span>
                    </div>
                    <b-input-group class="shadow-none" :style="buttonStyle">
                      <b-form-input
                        @focus="isInputFocused = true"
                        @blur="isInputFocused = false"
                        ref="contact_number"
                        size="lg"
                        name="contact_number"
                        :readonly="interaction[selected_line] != null"
                        v-on:keydown.enter.prevent="call_request"
                        class="text-center font-weight-bolder font-medium-5 border-0 shadow-none rounded"
                        v-model="contact_number"
                        :style="buttonStyle"
                      >
                      </b-form-input>
                      <b-input-group-append
                        class="d-flex justify-content-center align-items-center"
                      >
                        <span
                          v-if="call_timer > 0"
                          class="text-center font-weight-lighter mr-1"
                          >{{ call_timer_text }}</span
                        >
                        <span v-else class="text-center font-weight-lighter">{{
                          last_call_event
                        }}</span>
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="interaction['1'] != null" class="d-inline-block">
                      <span>
                        <b-button
                          v-b-tooltip.hover.v-secondary
                          v-b-tooltip.hover.bottom="lang('t_finishCode')"
                          v-b-tooltip="{ customClass: 'cZIndex' }"
                          squared
                          class="py-50 px-50"
                          :variant="'flat-primary'"
                          size="sm"
                          @click="modal_finish_code_copy = true"
                        >
                          <i class="bi bi-check-circle"></i>
                        </b-button>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex align-items-center ml-3">
                    <!-- Queue -->
                    <b-dropdown
                      toggle-class="text-decoration-none bg-transparent pl-0"
                      no-caret
                      menu-class="d-menu-class font-small-3 py-0"
                      size="sm"
                      variant="link"
                      class="bg-transparent"
                      @hidden="
                        queueFilterSearch = '';
                        classificationFilterSearch = '';
                      "
                    >
                      <template #button-content>
                        <span
                          class="d-inline-block text-truncate text-secondary"
                          style="max-width: 7rem"
                        >
                          {{
                            queues.find(
                              (e) => e.internal_name == selected_queue
                            )
                              ? queues.find(
                                  (e) => e.internal_name == selected_queue
                                ).display_name
                              : selected_queue
                          }}
                        </span>
                      </template>
                      <b-form-input
                        v-model="queueFilterSearch"
                        size="sm"
                        :placeholder="lang('t_search')"
                      ></b-form-input>
                      <b-dropdown-item
                        @click="onSelectedQueueChange(item)"
                        v-for="(item, i) in queueFiltered"
                        :key="i"
                      >
                        {{
                          queues.find((e) => e.internal_name == item)
                            ? queues.find((e) => e.internal_name == item)
                                .display_name
                            : item
                        }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <!-- Classification -->
                    <b-dropdown
                      toggle-class="text-decoration-none bg-transparent pl-0"
                      no-caret
                      menu-class="font-small-3 py-0"
                      size="sm"
                      v-if="selected_queue"
                      variant="link"
                      class="bg-transparent"
                      @hidden="
                        queueFilterSearch = '';
                        classificationFilterSearch = '';
                      "
                    >
                      <template #button-content>
                        <div class="d-flex align-items-center">
                          <span class="text-secondary">{{
                            selected_dial_plan
                          }}</span>
                        </div>
                      </template>

                      <b-form-input
                        v-model="classificationFilterSearch"
                        size="sm"
                        :placeholder="lang('t_search')"
                      ></b-form-input>

                      <b-dropdown-item
                        @click="
                          () => {
                            selected_dial_plan = item.name;
                            is_international_call =
                              item.international_call || false;
                          }
                        "
                        v-for="(item, i) in classificationFiltered"
                        :key="i"
                      >
                        {{ `${item.name}` }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-card-text>

                <!-- 123 -->
                <b-card-text :class="[dynamicMargin, 'my-2']">
                  <b-row>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('1')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">1</h3>
                          <span class="font-small-1"></span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('2')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">2</h3>
                          <span class="font-small-1">ABC</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('3')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">3</h3>
                          <span class="font-small-1">DEF</span>
                        </div>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-text>

                <!-- 456 -->
                <b-card-text :class="[dynamicMargin, 'my-2']">
                  <b-row>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('4')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">4</h3>
                          <span class="font-small-1">GHI</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('5')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">5</h3>
                          <span class="font-small-1">JKL</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('6')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">6</h3>
                          <span class="font-small-1">MNO</span>
                        </div>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-text>

                <!-- 789 -->
                <b-card-text :class="[dynamicMargin, 'my-2']">
                  <b-row>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('7')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">7</h3>
                          <span class="font-small-1">PQRS</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('8')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">8</h3>
                          <span class="font-small-1">TUV</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('9')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">9</h3>
                          <span class="font-small-1">WXYZ</span>
                        </div>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-text>

                <!-- *0# -->
                <b-card-text :class="[dynamicMargin, 'my-2']">
                  <b-row>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('*')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">✻</h3>
                          <span class="font-small-1"></span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('0')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">0</h3>
                          <span class="font-small-1">+</span>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        @click="write_phone('#')"
                        class="btn-icon rounded-circle"
                        variant="flat-secondary"
                      >
                        <div
                          class="text-center align-items-center align-items-center"
                          :style="{ width: '3rem', height: '3rem' }"
                        >
                          <h3 class="mb-0 font-weight-bolder">#</h3>
                          <span class="font-small-1"></span>
                        </div>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-text>

                <!-- CALL -->
                <b-card-text :class="[dynamicMargin, 'my-2', 'mt-3']">
                  <b-row>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-button
                        v-if="interaction[selected_line] != null"
                        @click="hangup"
                        :class="['btn-icon', 'rounded-circle']"
                        variant="danger"
                      >
                        <div
                          :style="{ width: '3rem', height: '3rem' }"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <i class="bi bi-x-lg font-medium-5"></i>
                        </div>
                      </b-button>
                      <b-button
                        v-else
                        @click="call_request"
                        :class="['btn-icon', 'rounded-circle']"
                        variant="success"
                      >
                        <div
                          :style="{ width: '3rem', height: '3rem' }"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <i class="bi bi-telephone-fill font-medium-5"></i>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col
                      cols="4"
                      class="d-flex justify-content-center align-items-center"
                    >
                    </b-col>
                  </b-row>
                </b-card-text>
                <div :class="[dynamicMargin, 'mt-3']">
                  <!-- style="margin-bottom: 7rem"
                  class="fixed-bottom" -->
                  <div class="d-flex align-items-center justify-content-center">
                    <b-button
                      v-if="other_permission.includes('agent_list')"
                      @click="modal_agent_list = true"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="lang('t_agentList')"
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i class="bi bi-people font-medium-3"></i>
                    </b-button>
                    <b-button
                      @click="mute"
                      v-if="interaction[selected_line] != null"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="
                        interaction[selected_line] != null
                          ? interaction[selected_line].isMuted().audio
                            ? lang('t_microphoneOn')
                            : lang('t_microphoneOff')
                          : lang('t_microphoneOff')
                      "
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i
                        class="bi bi-mic-mute font-medium-3"
                        :class="
                          interaction[selected_line] != null
                            ? interaction[selected_line].isMuted().audio
                              ? 'bi-mic'
                              : 'bi-mic-mute'
                            : 'bi-mic-mute'
                        "
                      ></i>
                    </b-button>
                    <b-button
                      v-if="interaction[selected_line] != null"
                      @click="hold"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      v-b-tooltip.hover.top="
                        interaction[selected_line] != null
                          ? interaction[selected_line].isOnHold().local
                            ? lang('t_continue')
                            : lang('t_hold')
                          : lang('t_hold')
                      "
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i
                        class="bi font-medium-3"
                        :class="
                          interaction[selected_line] != null
                            ? interaction[selected_line].isOnHold().local
                              ? 'bi-play'
                              : 'bi-pause'
                            : 'bi-pause'
                        "
                      ></i>
                    </b-button>
                    <b-button
                      v-if="
                        selected_line != 2 && interaction[selected_line] != null
                      "
                      :disabled="interaction[selected_line] == null"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      v-b-tooltip.hover.top="lang('t_transfer')"
                      @click="open_transfer_modal"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i class="bi bi-telephone-forward font-medium-3"></i>
                    </b-button>
                    <b-button
                      v-if="
                        selected_line != 2 && interaction[selected_line] != null
                      "
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      v-b-tooltip.hover.top="lang('t_conference')"
                      @click="openConferenceModal"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i class="bi bi-person-add font-medium-3"></i>
                    </b-button>
                    <b-button
                      v-if="
                        !other_permission.includes('dont_change_survey') &&
                        selected_line != 2 &&
                        interaction[selected_line] != null &&
                        dtmf_list.find((e) => e.includes('Anket'))
                      "
                      :disabled="interaction[selected_line] == null"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      v-b-tooltip.hover.top="
                        dtmf_list.find((e) => e.includes('Anket=Evet')) != null
                          ? lang('t_surveyCancel')
                          : lang('t_surveyApprove')
                      "
                      @click="set_survey_state"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i
                        class="bi font-medium-3"
                        :class="
                          dtmf_list.find((e) => e.includes('Anket=Evet')) !=
                          null
                            ? 'bi-clipboard2-x'
                            : 'bi-clipboard-check'
                        "
                      ></i>
                    </b-button>
                    <b-button
                      v-if="
                        dtmf_list.length > 0 &&
                        other_permission.includes('show_ivr_dial')
                      "
                      :disabled="interaction[selected_line] == null"
                      @click="modal_dtmf_list = true"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.top="lang('t_showInputs')"
                      v-b-tooltip="{ customClass: 'cZIndex' }"
                      :class="['btn-icon']"
                      variant="flat-secondary"
                    >
                      <i class="bi bi-eye font-medium-3"></i>
                    </b-button>
                  </div>
                </div>
              </b-card-body>
              <!-- CALL ACTION-->
            </div>

            <!-- ÇALIŞMA ALANI -->

            <b-card
              v-if="chat_panel_toggle && qm_socket_connected"
              no-body
              style="max-heigth: 100%; height: 100%; margin: 0"
              class="ma-0"
            >
              <div>
                <b-tabs fill v-if="chatpanel == false">
                  <b-tab :title="lang('t_conversations')">
                    <!-- username != chat._id.messages[0].From
                        ? chat._id.messages[0].From
                        : chat._id.messages[0].To -->
                    <div
                      v-for="(chat, i) in recent"
                      :key="i"
                      @click="continueNewChat(chat)"
                      class="cursor-pointer my-50 mx-50 employee-task d-flex justify-content-between align-items-center"
                    >
                      <b-media no-body>
                        <b-media-aside class="mr-75">
                          <b-avatar
                            class="badge-glow"
                            :variant="getChatColor(chat)"
                            badge-variant="success"
                          >
                            {{
                              (username != chat._id.messages[0].From
                                ? chat._id.messages[0].From
                                : chat._id.messages[0].To
                              )
                                .charAt(0)
                                .toUpperCase()
                            }}
                            <template
                              #badge
                              v-if="
                                userUnRead.hasOwnProperty(
                                  chat._id.messages[0].From
                                ) && userUnRead[chat._id.messages[0].From] > 0
                              "
                            >
                            </template>
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h6 class="mb-0">
                            {{
                              username != chat._id.messages[0].From
                                ? chat._id.messages[0].From
                                : chat._id.messages[0].To
                            }}
                          </h6>
                          <small
                            style="
                              display: inline-block;
                              width: 8vw;
                              white-space: nowrap;
                              overflow: hidden !important;
                              text-overflow: ellipsis;
                            "
                            >{{ chat._id.messages[0].msg }}</small
                          >
                        </b-media-body>
                      </b-media>
                    </div>
                  </b-tab>
                  <b-tab :title="lang('t_announcementHistory')">
                    <div style="max-height: 70vh; overflow-y: auto">
                      <div v-for="(item, i) in announcements" :key="i">
                        <b-card
                          no-body
                          border-variant="primary"
                          class="mx-50 mb-50"
                        >
                          <b-card-text
                            @click="showAnnounceDetails(item)"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="lang('t_showDetail')"
                            class="cursor-pointer"
                          >
                            <div class="mx-50">
                              <small class="font-weight-bolder">
                                {{
                                  item.sender.includes("@")
                                    ? item.sender.split("@")[0]
                                    : item.sender
                                }}
                                -
                                {{ msgTime(item.announcements.Timestamp) }}
                              </small>
                            </div>
                            <div class="mx-50">
                              <small
                                style="
                                  display: inline-block;
                                  width: 8vw;
                                  white-space: nowrap;
                                  overflow: hidden !important;
                                  text-overflow: ellipsis;
                                "
                                >{{ item.announcements.msg }}</small
                              >
                            </div>
                          </b-card-text>
                        </b-card>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
                <div class="mt-3 chat-panel-container" v-else>
                  <div class="chat_panel_inner">
                    <b-container class="bg-primary mb-1 mt-1" fluid>
                      <b-row align-h="between" align-v="center">
                        <b-button
                          size="sm"
                          variant="primary"
                          class="btn-rounded"
                          @click="closeChatPanel()"
                        >
                          <feather-icon
                            class="light-text"
                            size="14"
                            icon="ArrowLeftIcon"
                          />
                        </b-button>
                        <b-badge variant="primary">
                          {{
                            active_chat.username.includes("@")
                              ? active_chat.username.split("@")[0]
                              : active_chat.username
                          }}</b-badge
                        >
                      </b-row>
                    </b-container>

                    <div id="msg_div" class="messages">
                      <div
                        class="msg"
                        v-for="(value, key) in active_chat.messages"
                        :key="key"
                      >
                        <span
                          :class="
                            value.to == active_chat.username
                              ? 'msg_to'
                              : 'msg_from'
                          "
                          >{{ value.message }}
                          <small class="time">
                            {{ msgTime(value.time) }}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div class="mt-1">
                      <b-input-group>
                        <b-form-input
                          ref="msg"
                          v-model="message"
                          @keyup.enter="sendMsg()"
                          :placeholder="lang('t_yourMessage')"
                        ></b-form-input>
                        <b-input-group-append is-text>
                          <feather-icon
                            icon="SendIcon"
                            class="cursor-pointer"
                            @click="sendMsg"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>

          <!-- CHAT -->
          <div
            class="slide-in"
            v-show="
              show_chat && qm_socket_connected && !show_real_time_transcript
            "
          >
            <div
              style="max-heigth: 100%; height: 100%; margin: 0; overlow-y: auto"
              class="ma-0"
            >
              <div v-if="chatpanel == false">
                <!-- SEARCH AND START NEW CHAT -->
                <div class="mx-1 d-flex align-items-center mb-1 slide-in">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>

                    <b-form-input
                      @focus="isInputFocused = true"
                      @blur="isInputFocused = false"
                      :placeholder="lang('t_search')"
                    ></b-form-input>
                  </b-input-group>
                  <b-button
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_announcementHistory')"
                    v-b-tooltip="{ customClass: 'cZIndex' }"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle py-50 px-50 ml-50"
                    v-if="!panels.new_chat && !showAnnoucementHistory"
                    @click="showAnnoucementHistory = true"
                  >
                    <i class="bi bi-clock-history font-medium-1"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="lang('t_startChat')"
                    v-b-tooltip="{ customClass: 'cZIndex' }"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle py-50 px-50 ml-50"
                    @click="onNewChatClick"
                    v-if="!panels.new_chat && !showAnnoucementHistory"
                  >
                    <feather-icon
                      class="pa-0 ma-0"
                      size="12"
                      icon="PlusIcon"
                    ></feather-icon>
                  </b-button>
                  <b-button
                    variant="flat-secondary"
                    class="btn-icon rounded-circle py-50 px-50 ml-50"
                    @click="
                      () => {
                        panels.new_chat = false;
                        showAnnoucementHistory = false;
                      }
                    "
                    v-if="
                      panels.new_chat == true || showAnnoucementHistory == true
                    "
                  >
                    <feather-icon
                      class="pa-0 ma-0"
                      size="12"
                      icon="XIcon"
                    ></feather-icon>
                  </b-button>
                </div>
                <!-- CONVERSATIONS -->
                <div class="slide-in" v-if="showAnnoucementHistory == true">
                  <div
                    v-for="(item, i) in announcements"
                    :key="i"
                    class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                    :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
                    @click="showAnnounceDetails(item)"
                  >
                    <b-avatar :variant="'light-secondary'" class="mr-1">
                      <i class="bi bi-megaphone font-medium-1"></i>
                    </b-avatar>
                    <div class="align-items-center w-100">
                      <div class="d-flex align-items-center">
                        <span :class="true ? 'font-weight-bold' : ''">
                          {{ item.sender.split("@")[0] }}
                        </span>
                        <span
                          :class="true ? 'font-weight-bold' : ''"
                          class="text-muted ml-auto font-small-2"
                          >{{
                            new Date(
                              item.announcements.Timestamp
                            ).toLocaleTimeString(
                              "tr-TR",
                              (options = {
                                hour: "numeric",
                                minute: "2-digit",
                              })
                            )
                          }}</span
                        >
                      </div>
                      <div class="d-flex align-items-center">
                        <span
                          :class="true ? 'font-weight-bold' : ''"
                          class="text-muted text-truncate"
                          style="width: 12vw"
                        >
                          {{ item.announcements.msg }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="!panels.new_chat">
                  <div
                    @click="continueNewChat(chat)"
                    v-for="(chat, i) in recent"
                    :key="i"
                    class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                    :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
                  >
                    <b-avatar
                      :text="getConversationLetter(chat)"
                      :variant="getChatColor(chat)"
                      class="mr-1"
                      badge-variant="success"
                    >
                      <template
                        #badge
                        v-if="
                          userUnRead.hasOwnProperty(
                            chat._id.messages[0].From
                          ) && userUnRead[chat._id.messages[0].From] > 0
                        "
                      >
                      </template>
                    </b-avatar>
                    <div class="align-items-center w-100">
                      <div class="d-flex align-items-center">
                        <span :class="true ? 'font-weight-bold' : ''">
                          {{
                            username != chat._id.messages[0].From
                              ? chat._id.messages[0].From.split("@")[0]
                              : chat._id.messages[0].To.split("@")[0]
                          }}
                        </span>
                        <span
                          :class="true ? 'font-weight-bold' : ''"
                          class="text-muted ml-auto font-small-2"
                          >{{
                            new Date(
                              chat._id.messages[0].Timestamp
                            ).toLocaleTimeString(
                              "tr-TR",
                              (options = {
                                hour: "numeric",
                                minute: "2-digit",
                              })
                            )
                          }}</span
                        >
                      </div>
                      <div class="d-flex align-items-center">
                        <span
                          :class="true ? 'font-weight-bold' : ''"
                          class="text-muted text-truncate"
                          style="width: 12vw"
                        >
                          {{ chat._id.messages[0].msg }}
                        </span>
                        <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide-in" v-else>
                  <div
                    @click="startNewChat(chat.nickname)"
                    v-for="(chat, i) in chat_users"
                    :key="i"
                    class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
                    :class="isDark ? 'hover-effect-dark' : 'hover-effect'"
                  >
                    <b-avatar
                      :text="getConversationLetter2(chat)"
                      :variant="'light-secondary'"
                      class="mr-1"
                    >
                    </b-avatar>
                    <div class="align-items-center w-100">
                      <div class="d-flex align-items-center">
                        <span :class="true ? 'font-weight-bold' : ''">
                          {{ chat.nickname.split("@")[0] }}
                        </span>
                        <!-- <span
                    :class="true ? 'font-weight-bold' : ''"
                    class="text-muted ml-auto font-small-2"
                    >{{
                      new Date(
                        chat._id.messages[0].Timestamp
                      ).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}</span
                  > -->
                      </div>
                      <div class="d-flex align-items-center">
                        <span
                          :class="true ? 'font-weight-bold' : ''"
                          class="text-muted text-truncate"
                          style="width: 12vw"
                        >
                          {{ "Konuşma başlatmak için tıklayın.." }}
                        </span>
                        <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="slide-in" v-else>
                <b-card no-body class="mb-0 rounded-0">
                  <div
                    class="border-bottom d-flex align-items-center px-1 py-1"
                  >
                    <b-button
                      @click="closeChatPanel()"
                      pill
                      class="mr-50 py-50 px-50"
                      variant="flat-secondary"
                    >
                      <i class="bi bi-arrow-left font-medium-1"></i>
                    </b-button>
                    <b-avatar
                      variant="light-secondary"
                      :text="getChatLetter()"
                      class="mr-1"
                    ></b-avatar>
                    <span>
                      {{
                        active_chat.username.includes("@")
                          ? active_chat.username.split("@")[0]
                          : active_chat.username
                      }}
                    </span>
                    <div class="ml-auto d-flex align-items-center">
                      <b-button
                        pill
                        class="ml-auto py-50 px-50 mx-50"
                        variant="flat-secondary"
                      >
                        <i class="bi bi-search font-medium-1"></i>
                      </b-button>
                      <b-dropdown
                        variant="flat-secondary"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        toggle-class="px-50 py-50 rounded-circle"
                        menu-class="d-menu-class"
                      >
                        <template #button-content>
                          <i
                            class="bi bi-three-dots-vertical font-medium-1"
                          ></i>
                        </template>
                      </b-dropdown>
                    </div>
                  </div>
                </b-card>

                <div
                  :style="{
                    backgroundImage: 'url(' + chatBackgroundImage + ')',
                  }"
                  class="chat-bg fade-in-effect"
                >
                  <div
                    id="msg_div"
                    class="messages"
                    style="height: 42.6rem; overflow-y: auto"
                  >
                    <div
                      class="msg d-inline-block my-0 py-0"
                      v-for="(value, key) in active_chat.messages"
                      :key="key"
                    >
                      <div
                        class="d-block my-50"
                        v-if="isNewDay(active_chat.messages, key)"
                      >
                        <div class="date-line">
                          <small class="line"></small>
                          <b-badge class="mt-50" pill variant="light-secondary">
                            <span class="font-weight-lighter">
                              {{ formatDate(value.time) }}
                            </span>
                          </b-badge>
                          <small class="line"></small>
                        </div>
                      </div>
                      <span
                        class=""
                        :class="
                          value.to == active_chat.username
                            ? 'msg_to bg-primary text-white'
                            : isDark
                            ? 'msg_from bg-secondary text-white'
                            : 'msg_from bg-white text-dark'
                        "
                        >{{ value.message }}
                        <small class="time text-muted">
                          {{
                            new Date(value.time).toLocaleTimeString(
                              "tr-TR",
                              (options = {
                                hour: "numeric",
                                minute: "2-digit",
                              })
                            )
                          }}
                        </small>
                      </span>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center px-1 py-1 bg-transparent border-top"
                  >
                    <b-button
                      disabled
                      pill
                      variant="flat-secondary"
                      class="py-50 px-50"
                    >
                      <i
                        style="
                          -webkit-transform: rotate(30deg);
                          -moz-transform: rotate(30deg);
                          -ms-transform: rotate(30deg);
                          -o-transform: rotate(30deg);
                          transform: rotate(30deg);
                          display: inline-block;
                        "
                        class="bi bi-paperclip font-medium-2"
                      ></i>
                    </b-button>
                    <b-button
                      pill
                      variant="flat-secondary"
                      class="py-50 px-50 mr-1"
                      id="note-popover-reactive-1"
                      ref="button2"
                    >
                      <i class="bi bi-bookmark-plus"></i>
                    </b-button>
                    <b-form-input
                      @focus="isInputFocused = true"
                      @blur="isInputFocused = false"
                      @keyup.enter="sendMsg()"
                      ref="msg"
                      v-model="message"
                      :placeholder="lang('t_yourMessage')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>

      <!-- </transition> -->
    </b-row>

    <b-modal
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_announcementPanel')"
      v-model="panels.anons"
    >
      <b-form-textarea
        v-model="announcement_message"
        :placeholder="lang('t_yourMessage')"
        rows="3"
      >
      </b-form-textarea>
    </b-modal>
    <b-modal
      @show="
        () => {
          finish_code_owner =
            finish_code_owner_copy != '' ? finish_code_owner_copy : 'owner';
        }
      "
      static
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      v-model="modal_finish_code"
      :title="lang('t_finishCode')"
    >
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            @click="save_finish_code"
          >
            {{ `${lang("t_save")} (${formatTime(acw_timer)})` }}
          </b-button>
        </div>
      </template>

      <div class="mb-1">
        <a
          v-if="
            current_call_info &&
            current_call_info.Extra &&
            !other_permission.includes('mask_remote_number')
          "
          class="mr-50"
          href="#"
          @click="doCopy(current_call_info.Extra.RemoteNumber)"
          >{{ current_call_info.Extra.RemoteNumber }}</a
        >
        <a
          v-if="current_call_info && current_call_info.customer_no"
          href="#"
          @click="doCopy(current_call_info.customer_no)"
          >{{ current_call_info.customer_no }}</a
        >
      </div>

      <div
        class="mb-1"
        v-if="other_permission.includes('finish_code_breakdown')"
      >
        <b-form-radio inline v-model="finish_code_owner" value="owner">
          {{ lang("t_owner") }}
        </b-form-radio>
        <b-form-radio inline v-model="finish_code_owner" value="third_party">
          {{ lang("t_thirdParty") }}
        </b-form-radio>
      </div>

      <div class="d-flex align-items-center mb-1">
        <!--  -->

        <v-select
          appendToBody
          :autoscroll="false"
          class="w-100"
          v-model="interaction_finish_code"
          :options="FilterFinishCodes"
          :reduce="(val) => val"
          :placeholder="lang('t_finishCode')"
          label="finish_code"
          @input="on_finish_code_change"
          :clearable="false"
        >
        </v-select>
        <b-form-checkbox
          v-b-tooltip.hover.v-primary
          v-b-tooltip.hover.bottom="lang('t_lastFinishCode')"
          v-if="
            other_permission.includes('set_is_last_finish_code') &&
            interaction_finish_code != ''
          "
          class="ml-1"
          v-model="interaction_finish_code.is_last_finish_code"
        >
        </b-form-checkbox>
        <!-- <b-button
          v-if="other_permission.includes('set_is_last_finish_code')"
          class="py-0 px-0 ml-1 no-hover border-0"
          @click="
            () => {
              interaction_finish_code.is_last_finish_code =
                !interaction_finish_code.is_last_finish_code;
            }
          "
          size="sm"
          variant="flat-secondary"
        >
          <i
            :class="[
              interaction_finish_code.is_last_finish_code
                ? 'text-warning bi bi-star-fill'
                : 'text-warning bi bi-star',
            ]"
          ></i>
        </b-button> -->
      </div>
      <b-form-group v-if="interaction_finish_code.require_date">
        <flat-pickr
          v-model="interaction_action_date"
          class="form-control"
          title="Aksiyon Tarihi"
          placeholder="Aksiyon Tarihi"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group v-if="interaction_finish_code.save_calendar">
        <div
          v-for="(item, index) in cal_colors"
          :key="index"
          class="p-1 d-inline-block rounded mr-1 cursor-pointer"
          :class="[
            `bg-${item.color}`,
            { 'border border-light': !index },
            interaction_color == item.color ? 'mark-active' : '',
          ]"
          @click="interaction_color = item.color"
        />
      </b-form-group>
      <b-form-group>
        <b-form-textarea
          v-model="interaction_note"
          :placeholder="lang('t_note')"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="after_call_break"
          class="custom-control-primary"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              {{ lang("t_changeStatusAfterTheCall") }}
            </span>
          </span>
        </b-form-checkbox>
        <v-select
          class="mt-1"
          v-if="after_call_break"
          v-model="after_call_break_status"
          :options="
            status.filter(
              (e) =>
                e.changeable &&
                !['Available', 'Offline'].includes(e.display_name)
            )
          "
          :reduce="(val) => val.display_name"
          :placeholder="lang('t_status')"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <!-- <b-form-group>
        <b-row align-h="between">
          <b-col>
            <b-form-checkbox
              v-model="interaction_last_finish_code"
              class="custom-control-primary"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                  {{ lang("t_finish_code") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox class="custom-control-primary">
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                  {{ lang("t_survey") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form-group> -->
    </b-modal>
    <b-modal
      static
      centered
      ok-only
      v-model="modal_finish_code_copy"
      :title="lang('t_finishCode')"
      :ok-title="lang('t_save')"
      @show="finish_code_owner_copy = 'owner'"
    >
      <div
        class="mb-1"
        v-if="other_permission.includes('finish_code_breakdown')"
      >
        <b-form-radio inline v-model="finish_code_owner_copy" value="owner">
          {{ lang("t_owner") }}
        </b-form-radio>
        <b-form-radio
          inline
          v-model="finish_code_owner_copy"
          value="third_party"
        >
          {{ lang("t_thirdParty") }}
        </b-form-radio>
      </div>

      <div class="d-flex align-items-center mb-1">
        <v-select
          appendToBody
          :autoscroll="false"
          class="w-100"
          v-model="interaction_finish_code_copy"
          :options="FilterFinishCodesCopy"
          :reduce="(val) => val"
          :placeholder="lang('t_finishCode')"
          label="finish_code"
          @input="on_finish_code_change_copy"
          :clearable="false"
        >
        </v-select>
        <b-form-checkbox
          v-b-tooltip.hover.v-primary
          v-b-tooltip.hover.bottom="lang('t_lastFinishCode')"
          v-if="
            other_permission.includes('set_is_last_finish_code') &&
            interaction_finish_code_copy != ''
          "
          class="ml-1"
          v-model="interaction_finish_code_copy.is_last_finish_code"
        >
        </b-form-checkbox>
        <!-- <b-button
          v-if="other_permission.includes('set_is_last_finish_code')"
          class="py-0 px-0 ml-1 no-hover border-0"
          @click="
            () => {
              interaction_finish_code.is_last_finish_code =
                !interaction_finish_code.is_last_finish_code;
            }
          "
          size="sm"
          variant="flat-secondary"
        >
          <i
            :class="[
              interaction_finish_code.is_last_finish_code
                ? 'text-warning bi bi-star-fill'
                : 'text-warning bi bi-star',
            ]"
          ></i>
        </b-button> -->
      </div>
      <b-form-group v-if="interaction_finish_code_copy.require_date">
        <flat-pickr
          v-model="interaction_action_date_copy"
          class="form-control"
          title="Aksiyon Tarihi"
          placeholder="Aksiyon Tarihi"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group v-if="interaction_finish_code_copy.save_calendar">
        <div
          v-for="(item, index) in cal_colors"
          :key="index"
          class="p-1 d-inline-block rounded mr-1 cursor-pointer"
          :class="[
            `bg-${item.color}`,
            { 'border border-light': !index },
            interaction_color_copy == item.color ? 'mark-active' : '',
          ]"
          @click="interaction_color_copy = item.color"
        />
      </b-form-group>
      <b-form-group>
        <b-form-textarea
          v-model="interaction_note_copy"
          :placeholder="lang('t_note')"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-modal
      static
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_extraTime')"
      centered
      v-model="modal_extra_time"
      :ok-title="lang('t_ok')"
      ok-only
      @ok="extra_time_ok"
      hide-header-close
    >
      <center>
        <h2>{{ extra_time_counter }}</h2>
      </center>
    </b-modal>
    <b-modal
      static
      style="z-index: 999; position: absolute"
      size="sm"
      v-if="![undefined, null, ''].includes(current_call_info)"
      no-close-on-esc
      no-close-on-backdrop
      centered
      :ok-title="lang('t_answer')"
      :cancel-title="lang('t_reject')"
      @ok="accept_interaction"
      @cancel="reject_interaction"
      ok-variant="success"
      cancel-variant="outline-danger"
      hide-header-close
      @show="phone_ring_start"
      @close="phone_ring_stop"
      @hide="phone_ring_stop"
      hide-footer
    >
      <div style="height: 20rem; overflow-y: auto">
        <div
          class="d-inline-block justify-content-center align-items-center text-center"
        >
          <span class="d-block mt-50">
            {{ "Gelen Arama..." }}
          </span>
          <span class="d-block mt-50 font-medium-5 font-weight-bolder">
            {{
              `${
                current_call_info.InteractionType != "Text"
                  ? other_permission.includes("mask_remote_number")
                    ? maskPhoneNumber(
                        current_call_info.Extra.RemoteNumber.substr(-11)
                      )
                    : current_call_info.Extra.RemoteNumber.substr(-11)
                  : other_permission.includes("mask_remote_number")
                  ? maskPhoneNumber(current_call_info.Extra.RemoteNumber)
                  : current_call_info.Extra.RemoteNumber
              }`
            }}
          </span>
          <div class="d-flex align-items-center text-center mt-50">
            <span class="d-block">
              {{
                getQueueName() + " | " + current_call_info.Extra.CallerIDName
                  ? current_call_info.Extra.CallerIDName
                  : ""
              }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-around mt-2 mb-1">
          <b-button
            @click="reject_interaction"
            :class="['btn-icon', 'rounded-circle']"
            variant="danger"
          >
            <div
              :style="{ width: '3rem', height: '3rem' }"
              class="d-flex justify-content-center align-items-center"
            >
              <i class="bi bi-x-lg font-medium-5"></i>
            </div>
          </b-button>
          <b-button
            @click="accept_interaction"
            :class="['btn-icon', 'rounded-circle']"
            variant="success"
          >
            <div
              :style="{ width: '3rem', height: '3rem' }"
              class="d-flex justify-content-center align-items-center"
            >
              <i class="bi bi-telephone-fill font-medium-5"></i>
            </div>
          </b-button>
        </div>
      </div>

      <!-- <b-container>
        <b-row>
          {{
            `${current_call_info.Extra.CallerIDName} - ${
              current_call_info.InteractionType != "Text"
                ? other_permission.includes("mask_remote_number")
                  ? maskPhoneNumber(
                      current_call_info.Extra.RemoteNumber.substr(-11)
                    )
                  : current_call_info.Extra.RemoteNumber.substr(-11)
                : other_permission.includes("mask_remote_number")
                ? maskPhoneNumber(current_call_info.Extra.RemoteNumber)
                : current_call_info.Extra.RemoteNumber
            }`
          }}
        </b-row>
      </b-container> -->
    </b-modal>
    <b-modal
      static
      style="z-index: 999; position: absolute"
      size="sm"
      v-if="![undefined, null, ''].includes(current_call_info)"
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_transfer')"
      centered
      :ok-title="lang('t_answer')"
      :cancel-title="lang('t_reject')"
      @ok="transfer_accept_interaction"
      @cancel="transfer_reject_interaction"
      ok-variant="success"
      cancel-variant="outline-danger"
      hide-header-close
      @show="phone_ring_start"
      @close="phone_ring_stop"
      @hide="phone_ring_stop"
    >
      <b-container>
        <b-row>
          {{
            `${
              current_call_info.Extra.CallerIDName
            } - ${current_call_info.Extra.RemoteNumber.substr(-11)}`
          }}
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      v-if="other_permission"
      size="lg"
      static
      :title="lang('t_agentList')"
      hide-footer
      scrollable
      centered
      v-model="modal_agent_list"
      :dialog-class="[
        other_permission.includes('agent_list_details') ? 'dClass75' : '',
      ]"
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        style="max-height: 50vh; overflow-y: auto"
        class="mt-1"
        :items="agent_list"
        :fields="agent_list_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'Username'"
      >
        <template #cell(Username)="data">
          <div class="d-flex align-items-center">
            <b-avatar class="mr-1" variant="light-secondary">
              {{
                `${data.value.split(".")[0][0].toUpperCase()}${data.value
                  .split(".")
                  [data.value.split(".").length - 1][0].toUpperCase()}`
              }}
            </b-avatar>
            <span> {{ data.value }}</span>
          </div>
        </template>
        <template #cell(Time)="data">
          {{ timer(data.value) }}
        </template>
        <template #cell(Productivity)="data">
          {{ `%${data.value.calculated}` }}
        </template>
        <template #cell(Queues)="data">
          {{ data.value.Value }}
        </template>
        <template #cell(Reason)="data">
          <b-badge
            :variant="
              reason_colors.hasOwnProperty(data.value)
                ? `light-${reason_colors[data.value]}`
                : 'light-primary'
            "
            style="width: 9em"
            >{{ data.value }}</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            :disabled="data.item.Reason != 'Available'"
            boundary="window"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            menu-class="d-menu-class"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="startInternalCall(data.item)">
              <feather-icon icon="PhoneCallIcon" />
              <span class="align-middle ml-50">{{ lang("t_call") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      size="lg"
      :title="lang('t_transfer')"
      hide-footer
      scrollable
      centered
      v-model="modal_transfer_list"
    >
      <!-- <b-tabs fill>
        <b-tab
          @click="transfer_tab_changed(item)"
          v-for="(item, i) in transfer_tabs"
          :title="item.display_name"
          :key="i"
        ></b-tab>
      </b-tabs> -->
      <div class="d-flex align-items-center my-3">
        <v-select
          appendToBody
          class="w-50 mr-50"
          v-model="selected_transfer_tab"
          :options="transfer_tabs"
          :placeholder="lang('t_type')"
          :reduce="(option) => option.internal_name"
          label="display_name"
          @input="transfer_tab_changed"
          :clearable="false"
        >
        </v-select>

        <v-select
          appendToBody
          class="w-50 ml-50"
          v-if="selected_transfer_tab == 'Agent'"
          v-model="selected_transfer_target"
          :options="agent_list"
          :placeholder="lang('t_target')"
          :reduce="(option) => (option ? option.Username : '')"
          :selectable="
            (option) =>
              option.Reason == 'Available' ||
              status.find((e) => e.display_name == option.Reason).can_transfer
          "
          label="Username"
          :clearable="false"
        >
        </v-select>
        <v-select
          appendToBody
          class="w-50 ml-50"
          v-else
          v-model="selected_transfer_target"
          :options="transfer_list"
          :placeholder="lang('t_target')"
          :reduce="(option) => option.internal_name"
          label="display_name"
          :clearable="false"
        >
        </v-select>
        <b-button
          @click="transfer_interaction(selected_transfer_target)"
          class="ml-1"
          variant="primary"
        >
          <div class="d-flex align-items-center">
            <i class="bi bi-telephone-forward font-medium-1 mr-50"></i>
            <span>{{ lang("t_transfer") }}</span>
          </div>
        </b-button>
      </div>
      <div>
        <b-form-textarea
          rows="3"
          v-model="transfer_note"
          :placeholder="lang('t_note')"
        ></b-form-textarea>
      </div>

      <div
        class="mt-3"
        style="max-height: 50vh; height: 50vh; overflow-y: auto"
        v-if="selected_transfer_tab == 'External'"
      >
        <b-form-input
          trim
          v-model="external_transfer.number"
          placeholder="Numara"
        />
        <v-select
          class="mt-1"
          v-model="external_transfer.line_group"
          :options="line_groups"
          :placeholder="lang('t_lineGroup')"
          :reduce="(option) => option"
          label="display_name"
        >
        </v-select>
        <b-button
          class="mt-1"
          block
          v-b-tooltip.hover.v-primary
          v-b-tooltip.hover.bottom="lang('t_transfer')"
          @click="null"
          variant="outline-primary"
        >
          <feather-icon icon="SendIcon" />
          {{ lang("t_transfer") }}
        </b-button>
      </div>
      <!-- <div
        class="mt-3"
        style="max-height: 50vh; height: 50vh; overflow-y: auto"
        v-else
      >
        <div>
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            :placeholder="lang('t_search')"
          />
        </div>
        <b-table
          style="max-height: 50vh; overflow-y: auto"
          class="mt-1"
          :items="selected_transfer_tab == 'Agent' ? agent_list : transfer_list"
          :fields="
            selected_transfer_tab == 'Agent'
              ? agent_list_fields
              : transfer_list_fields
          "
          ref="table"
          striped
          reactive
          responsive="sm"
          :filter="searchQuery"
          :sortBy="
            selected_transfer_tab == 'Agent' ? 'Username' : 'display_name'
          "
        >
          <template #cell(Reason)="data">
            <b-badge
              :variant="
                colors.hasOwnProperty(data.value)
                  ? colors[data.value]
                  : 'primary'
              "
              style="width: 9em"
              >{{ data.value }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <b-button
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom="lang('t_transfer')"
              size="sm"
              @click="
                transfer_interaction(
                  selected_transfer_tab == 'Agent'
                    ? data.item.Username
                    : data.item.internal_name
                )
              "
              variant="outline-primary"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
          </template>
        </b-table>
      </div> -->
    </b-modal>
    <b-modal
      static
      :title="lang('t_agentList')"
      hide-footer
      scrollable
      centered
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        class="mt-1"
        :items="chat_users"
        :fields="chat_users_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'nickname'"
      >
        <template #cell(nickname)="data">
          {{ data.value.split("@")[0] }}
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            @click="startNewChat(data.item.nickname)"
            variant="outline-primary"
          >
            <feather-icon icon="SendIcon" />
            {{ lang("t_startChat") }}
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      :title="lang('t_inputList')"
      ok-only
      scrollable
      centered
      v-model="modal_dtmf_list"
    >
      <b-row v-for="(item, i) in dtmf_list" :key="i">
        <span style="font-size: 1.3rem" class="mx-1">
          {{ `${i + 1}) ${item}` }}
        </span>
      </b-row>
    </b-modal>
    <b-modal
      size="lg"
      :title="lang('t_conference')"
      hide-footer
      scrollable
      centered
      v-model="conference_modal"
    >
      <div class="mb-5">
        <div class="d-flex align-items-center">
          <b-form-input
            class="mr-1"
            trim
            v-model="conference_phone"
          ></b-form-input>
          <b-button variant="primary" @click="startConference()">{{
            lang("t_add")
          }}</b-button>
        </div>

        <b-table
          class="mt-1"
          :items="conference_data"
          :fields="conference_data_fields"
          striped
          responsive="sm"
        >
          <template #cell(Status)="data">
            <div class="d-flex align-item-center">
              <b-badge
                class="mr-50"
                :variant="GetConferenceStatusColor(data.value)"
                >{{ data.value }}</b-badge
              >
              <!-- <i v-if="data.item.Muted" class="bi bi-mic-mute"></i>
              <i v-if="data.item.Deafen" class="bi bi-volume-mute"></i> -->
            </div>
          </template>
          <template #cell(Time)="data">
            <div v-if="['Ringing', 'Accepted'].includes(data.item.Status)">
              {{ toHHMMSS((new Date().getTime() - Number(data.value)) / 1000) }}
            </div>
          </template>
          <template #cell(Actions)="data">
            <div class="d-flex align-item-center">
              <b-button
                @click="ConferenceControl(data.item, 'Mute')"
                class="px-50 py-50"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_microphoneOff')"
                v-if="!data.item.Muted"
                variant="flat-secondary"
              >
                <i class="font-medium-1 bi bi-mic-mute"></i>
              </b-button>

              <b-button
                @click="ConferenceControl(data.item, 'Unmute')"
                class="px-50 py-50"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_microphoneOn')"
                v-else
                variant="flat-danger"
              >
                <i class="font-medium-1 bi bi-mic"></i>
              </b-button>

              <b-button
                @click="ConferenceControl(data.item, 'Deafen')"
                class="mx-25 px-50 py-50"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_undeafen')"
                v-if="!data.item.Deafen"
                variant="flat-secondary"
              >
                <i class="font-medium-1 bi bi-volume-mute"></i>
              </b-button>

              <b-button
                @click="ConferenceControl(data.item, 'Undeafen')"
                v-else
                class="mx-25 px-50 py-50"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_deafen')"
                variant="flat-danger"
              >
                <i class="font-medium-1 bi bi-volume-down"></i>
              </b-button>

              <b-button
                class="px-50 py-50"
                v-b-tooltip.hover.v-secondary
                v-b-tooltip.hover.bottom="lang('t_hangup')"
                @click="ConferenceControl(data.item, 'Disconnect')"
                variant="flat-danger"
              >
                <i class="font-medium-1 bi bi-x"></i>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BFormRadio,
  BDropdown, BPagination, BTable, BSpinner, BCardHeader, BCardBody, BImg, BInputGroupPrepend,
  BDropdownDivider, BIcon,
  BDropdownItem, BFormCheckbox, BFormGroup, BFormTextarea, BModal, BCardText, BInputGroup, BInputGroupAppend, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, VBTooltip, BFormInput, BButton, BButtonGroup, BRow, BCol, BCard, BTabs, BTab, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { $themeColors } from '@themeConfig'
import 'animate.css'
import flatPickr from 'vue-flatpickr-component'
var audio = new Audio("sounds/voyce_ringtone_01.mp3");
var hangup = new Audio("sounds/hangup.mp3");

audio.loop = true;

var conference_declined_audio = new Audio("sounds/conference_declined_tone.wav");
var conference_accepted_audio = new Audio("sounds/conference_tone.wav");
var conference_dial_audio = new Audio("sounds/conference_dial_tone.wav");
conference_dial_audio.loop = true;
import CalendarNotification from "./CalendarNotification.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed } from '@vue/composition-api'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      buttonStyle: {
        backgroundColor: window.localStorage.getItem('vuexy-skin') == 'dark' ? '#353a4d' : "#f9f9f9"

        // backgroundColor: "#f8f8f9",
      },
      logo_sq: globalThis.env.MENU_LOGO_SQ,
      baseText: globalThis._lang("t_incomingCall"),
      isInputFocused: false,
      show_chat: false,
      is_window_active: true,
      username: '',
      finish_codes: [],
      interval_agent_list: null,
      interval_conference: null,
      queues: [],
      dial_plans: [],
      classifications: [],
      classification_queues: [],
      transfer_tabs: [
        { internal_name: 'Agent', display_name: globalThis._lang("t_agent"), },
        { internal_name: 'Queue', display_name: globalThis._lang("t_queue"), },
        { internal_name: 'IVR', display_name: globalThis._lang("t_IVR"), },
        { internal_name: 'vIVR', display_name: globalThis._lang("t_vIVR"), },
        { internal_name: 'pbIVR', display_name: globalThis._lang("t_phoneBook"), },
        // { internal_name: 'External', display_name: 'Dış Numara' },
      ],
      selected_transfer_tab: 'Agent',
      transfer_note: '',
      hold_time: 0,
      hold_interval: null,
      selected_transfer_target: '',
      selected_queue: '',
      selected_dial_plan: '',
      selected_line: '1',
      selected_panel: 'softphone',
      contact_number: '',
      pin_soft_phone: true,
      phone_panel_toggle: true,
      chat_panel_toggle: false,
      modal_finish_code: false,
      modal_finish_code_copy: false,
      modal_extra_time: false,
      modal_dtmf_list: false,
      modal_transfer: false,
      show_real_time_transcript: false,
      conference_modal: false,
      conference_data: [],
      conference_data_fields: [
        { key: 'RemoteNumber', label: globalThis._lang('t_rRemoteNumber'), sortable: true },
        { key: 'Status', label: globalThis._lang('t_status'), sortable: true },
        { key: 'Time', label: globalThis._lang('t_statusLength'), sortable: true },
        { key: 'Actions', label: '', sortable: true },
      ],
      modal_incoming: false,
      extra_time_counter: 0,
      interaction_length: 1,
      interaction: {
        "1": null,
        "2": null,
      },
      dtmf_number: '',
      dtmf_controller: {},
      last_call_event: '',
      interval: null,
      call_timer: 0,
      call_timer_text: '00:00',
      //CHAT
      tab: null,
      tab_items: ["Konuşmalar", "Anons Geçmişi"],
      souffle_agent: "",
      souffle_message: "",
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      souffle_snackbar: false,
      souffle_snackbarMessage: "",
      souffle_snackbarTitle: "",
      souffle_snackbarColor: "",
      showAnnoucementHistory: false,
      chatpanel: false,
      search_chat_list: "",
      maximize: false,
      announcement_message: "",
      message: "",
      chat_users: [],
      userUnRead: {},
      totalUnRead: 0,
      online_users: [],
      anonsment_detail: false,
      anonsment_detail_content: {
        sender: "",
        time: "",
        msg: "",
      },
      active_chat: {
        username: "",
        user_from: "",
        user_to: "",
        messages: [],
      },
      suffles: [],
      announcements: [],
      classifications_id_list: [],
      panels: {
        anons: false,
        sufle: false,
        new_chat: false,
      },
      prev_status: null,
      recent: [],
      status: [],
      acw: 0,
      acw_timer: 0,
      conference_interval: null,
      transfer_interval: null,
      acw_interval: null,
      extra_time_interval: null,
      interaction_finish_code: '',
      interaction_action_date: '',
      interaction_note: '',
      interaction_color: 'primary',
      interaction_last_finish_code: false,
      finish_code_owner: 'owner',

      finish_code_owner_copy: 'owner',
      interaction_action_date_copy: '',
      interaction_finish_code_copy: '',
      interaction_color_copy: 'primary',
      interaction_note_copy: '',

      socket: '',
      boxTwo: false,
      current_call_info: null,
      cal_colors: [
        {
          color: 'danger',
          label: 'Personal',
        },
        {
          color: 'primary',
          label: 'Business',
        },
        {
          color: 'warning',
          label: 'Family',
        },
        {
          color: 'success',
          label: 'Holiday',
        },
        {
          color: 'info',
          label: 'ETC',
        },
      ],
      reason_colors: {
        "Available": "success",
        "Lunch": "warning",
        "Break": "warning",
        "In Call - Outbound": "info",
        "In Call - Inbound": "info",
        "In Call - Dialer": "info",
        "In Call - Transfer": "info",
        "In Call - Internal": "info",
        "In Text": "info",
        "Awaiting Transfer": "info",
        "ACW": "secondary",
        "Calling": "info",
        "Ringing": "info",
        "Offline": "danger",
      },
      modal_agent_list: false,
      modal_transfer_list: false,
      searchQuery: '',
      transfer_list: [],
      transfer_list_fields: [
        { key: 'display_name', label: globalThis._lang("t_displayName"), },
        { key: 'actions', label: globalThis._lang("t_action"), },
      ],
      agent_list: [],
      agent_list_fields: [
        { key: 'Username', label: globalThis._lang('t_userName'), sortable: true },
        { key: 'Reason', label: globalThis._lang('t_status'), sortable: true },
        { key: 'Time', label: globalThis._lang('t_time'), sortable: true },
        // { key: 'Productivity', label: globalThis._lang('t_productivity'), sortable: true },
        // { key: 'AcceptedCalls', label: globalThis._lang('t_answered'), sortable: true },
        // { key: 'MissedCalls', label: globalThis._lang('t_rInboundFail'), sortable: true },
        // { key: 'AcceptedDialerCalls', label: globalThis._lang('t_rDialerSuccess'), sortable: true },
        // { key: 'MissedDialerCalls', label: globalThis._lang('t_rDialerFail'), sortable: true },
        // { key: 'SuccessfulOutboundCalls', label: globalThis._lang('t_successfullCall'), sortable: true },
        // { key: 'FailedOutboundcalls', label: globalThis._lang('t_unsuccessfullCall'), sortable: true },
      ],
      chat_users_fields: [
        { key: 'nickname', label: globalThis._lang("t_agent"), },
        { key: 'actions', label: globalThis._lang("t_action"), },
      ],
      colors: {
        'Avail': 'success',
        'Lunch': 'danger',
        'Break': 'danger',
        'In Call - Outbound': 'warning',
        'In Call - Inbound': 'warning',
        'In Call - Dialer': 'warning',
        'ACW': 'info',
        'Ringing': 'info',
        'Offline': 'secondary',
      },
      events: [],
      calendarInterval: {},
      ring_audio: null,
      old_call_status: '',
      qm_socket_connected: false,
      after_call_break: false,
      after_call_break_status: '',
      is_international_call: false,
      dtmf_list: [],
      tmp_dtmf_string: [],
      dtmf_interval: null,
      other_permission: [],
      transfer_targets: {
        Queue: [],
        Agent: [],
        IVR: [],
        vIVR: [],
        pbIVR: [],
      },
      external_transfer: {
        number: '',
        line_group: {}
      },
      line_groups: [],
      toast_limit: 0,
      FinishCodesByQueue: new Map(),
      showContactNumberInterval: null,
      showContactNumber: false,
      internal_session: null,
      conference_phone: '',
      queueFilterSearch: '',
      classificationFilterSearch: '',
    }
  },
  computed: {
    FilterFinishCodes() {
      console.log("FilterFinishCodes", "Start")
      let _finishCodes = [];
      if (this.other_permission.includes('show_all_finish_codes')) {
        _finishCodes = this.finish_codes;
        return _finishCodes;
      } else {
        console.log("FilterFinishCodes", this.current_call_info);
        _finishCodes = this.finish_codes.filter((e) =>
          e.is_reached == (this.current_call_info == null ? false : (this.current_call_info.is_answered ?? false)));
        console.log("1", _finishCodes);
        if (this.other_permission.includes('finish_code_breakdown')) {
          if (this.finish_code_owner == 'owner') {
            console.log("2");

            return _finishCodes.filter(e => e.is_owner);
          } else {
            return _finishCodes.filter(e => !e.is_owner)
          }
        } else {
          return _finishCodes;
        }
      }
    },
    FilterFinishCodesCopy() {
      let _finishCodes = [];
      if (this.other_permission.includes('show_all_finish_codes')) {
        _finishCodes = this.finish_codes;
        return _finishCodes;
      } else {
        _finishCodes = this.finish_codes.filter((e) => {
          return e.is_reached === (
            this.current_call_info == null ? false : this.current_call_info.is_answered || false
          );
        });

        if (this.other_permission.includes('finish_code_breakdown')) {
          if (this.finish_code_owner_copy == 'owner') {
            return _finishCodes.filter(e => e.is_owner);
          } else {
            return _finishCodes.filter(e => !e.is_owner)
          }
        } else {
          return _finishCodes;
        }
      }
    },
    queueFiltered() {
      return this.classification_queues.filter(e => e.toLowerCase().includes(this.queueFilterSearch.toLowerCase()))
    },
    classificationFiltered() {
      return this.classifications.filter(
        (e) =>
          e.name.toLowerCase().includes(this.classificationFilterSearch.toLowerCase()) &&
          e.queue ==
          this.queues.find(
            (e) => e.internal_name == this.selected_queue
          ).internal_name
      )
    },
    testDebug() {
      return (this.baseText + this.dots);
    },
    chatBackgroundImage() {
      return this.isDark ? '/chat_bg_dark.jpg' : '/chat_bg.jpg';
    },
    dynamicMargin() {
      console.log("window.innerWidth", window.innerWidth)
      return window.innerWidth <= 1366 ? '' : 'mx-3';
    },
    dynamicMarginTop() {
      console.log("window.innerWidth", window.innerWidth)
      return window.innerWidth <= 1366 ? '' : 'mt-2';
    },
    maskPhoneNumber() {
      return globalThis.maskPhoneNumber;
    }
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    CalendarNotification, BSpinner, BIcon, BImg, BInputGroupPrepend, BFormRadio,
    BDropdown, BPagination, BTable, BCardHeader, BCardBody,
    BDropdownDivider,
    BDropdownItem, BFormCheckbox, BFormGroup, flatPickr, BFormTextarea, BModal, BCardText, BInputGroup, BInputGroupAppend, BContainer, BAvatar, BFormInput, AppBreadcrumb, BRow, BCol, BCard, BButton, BButtonGroup, vSelect, BTabs, BTab, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge
  },

  methods: {
    toHHMMSS(sec_num) {
      try {
        return new Date(sec_num * 1000).toISOString().substr(11, 8);
      } catch (error) {
        return sec_num;
      }
    },
    GetConferenceStatusColor(status) {
      if (status == 'Ringing') { return 'light-info' }
      else if (status == 'Accepted') { return 'light-success' }
      else if (status == 'Hangup') { return 'light-secondary' }
      else { return 'light-danger' }
    },
    AddNotification(obj) {
      globalThis.v_navbar.notifications.unshift(obj);
    },
    getTimeInfo() {
      var now = new Date();
      var hours = now.getHours();
      var minutes = now.getMinutes();

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;

      return hours + ":" + minutes;
    },
    ConferenceControl(item, type) {
      console.log("type", type);
      console.log("item", item);

      let _item = this.conference_data.find(e => e.RemoteNumber == item.RemoteNumber);

      globalThis._voyce.$qm.emit('qmgr:ConferenceControl', {
        VoyceInternalId: _item.VoyceInternalId,
        RemoteNumber: _item.RemoteNumber,
        Queue: _item.Queue,
        Classification: _item.Classification,
        ControlType: type,
        Channel: _item.Channel
      });

      if (type == 'Mute') { _item.Muted = true; }
      if (type == 'Unmute') { _item.Muted = false; }
      if (type == 'Deafen') { _item.Deafen = true; }
      if (type == 'Undeafen') { _item.Deafen = false; }
      this.conference_data = [...this.conference_data];
    },
    startConference() {
      if (globalThis.v_navbar.selected_status.display_name.includes('In Call')) {
        globalThis._voyce.$qm.emit('qmgr:StartConference', {
          VoyceInternalId: this.current_call_info.Extra.VoyceInternalId,
          RemoteNumber: this.conference_phone,
          Queue: this.selected_queue,
          Classification: this.selected_dial_plan,
        });

        this.conference_data.push({
          RemoteNumber: this.conference_phone.slice(-10),
          Channel: '',
          Status: 'Ringing',
          Muted: false,
          Deafen: false,
          Time: new Date().getTime(),
          VoyceInternalId: this.current_call_info.Extra.VoyceInternalId,
          Queue: this.selected_queue,
          Classification: this.selected_dial_plan,
        });

        // this.conference_interval = setTimeout(() => {
        //   conference_dial_audio.play();
        // }, 1500);



        this.$toast({
          component: ToastificationContent,
          props: {
            title: globalThis._lang('t_conference'),
            icon: 'bi-exclamation-circle',
            text: globalThis._lang('t_conferenceCalling'),
            variant: 'info',
          },
        },
          {
            position: 'top-center',
            // toastClassName: "toast-property-warning",
          });
      }

    },
    openConferenceModal() {
      this.conference_phone = '';
      this.conference_modal = true;
    },
    startInternalCall(target_agent) {
      if (this.interaction[1] == null) {
        this.interaction[1] = globalThis.vSip.Call(`internal_${target_agent.Username}`, [
          `X-Domain: ${target_agent.Domain}`,
          `X-Type: InternalCall`,
          `X-Agent: ${globalThis.user.username}`,
        ]);

        this.contact_number = target_agent.Username;
        this.modal_agent_list = false;
        globalThis._voyce.$qm.emit('qmgr:Pause', {
          reason: 'In Call - Internal',
        });
      }
    },
    setAgentList: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      this.agent_list = response.filter(e => e && e.Status != 'Offline' && e.Username != globalThis.user.username && e.Queues.Value.some(queue => globalThis.user.selected_queues.includes(queue)));
      for (const row of this.agent_list) {
        row.Time = Number((new Date() - new Date(row.LastActionDate)) / 1000)
      }
    },
    timer(seconds) {
      let _m = ("" + (Math.floor(seconds / 60) % 60)).padStart(2, "0");
      let _h = ("" + Math.floor(seconds / 3600)).padStart(2, "0");
      let _s = ("" + Math.floor(seconds % 60)).padStart(2, "0");
      return `${_h}:${_m}:${_s}`;
    },
    handleKeydown(event) {
      if (this.interaction[this.selected_line] != null && !["INPUT", "TEXTAREA", "SELECT"].includes(document.activeElement.tagName)) {
        if (event.shiftKey && event.key === 'T') {
          this.hangup();
        }
        if (event.shiftKey && event.key === 'M') {
          this.mute();
        }
        if (event.shiftKey && event.key === 'H') {
          this.hold();
        }
        if (event.shiftKey && event.key === 'F') {
          this.open_transfer_modal();
        }
        return;
      }

      if (event.altKey) {
        return;
      }

      if (["INPUT", "TEXTAREA", "SELECT"].includes(document.activeElement.tagName)) {
        return;
      }

      if (this.interaction[this.selected_line] == null && this.showContactNumber && event.key == 'Enter') {
        this.call_request();
        this.showContactNumber = false;
        clearInterval(this.showContactNumberInterval);
        this.showContactNumberInterval = null;
        return;
      }

      if (this.showContactNumberInterval) {
        clearInterval(this.showContactNumberInterval);
        this.showContactNumberInterval = null;
      }
      this.showContactNumber = true;
      this.showContactNumberInterval = setTimeout(() => {
        this.showContactNumber = false;
        // this.contact_number = '';
      }, 2000);

      if (event.key >= 0 && event.key <= 9) {
        this.contact_number += event.key;
      } else if (event.key == 'Backspace') {
        this.contact_number = this.contact_number.slice(0, -1);
      }
    },
    openInternalCallModal() {
      console.log("openInternalCallModal", globalThis.LayoutContentRendererDefault.internal_session._request);

      let caller_name = '';
      if (globalThis.LayoutContentRendererDefault.internal_session && globalThis.LayoutContentRendererDefault.internal_session._request.from._display_name) {
        caller_name = globalThis.LayoutContentRendererDefault.internal_session._request.from._display_name.split('_')[0];
      }
      else if (globalThis.LayoutContentRendererDefault.internal_session && globalThis.LayoutContentRendererDefault.internal_session._request.headers['X-Caller-Id']) {
        caller_name = globalThis.LayoutContentRendererDefault.internal_session._request.headers['X-Caller-Id'][0].raw;
      } else {
        caller_name = 'Unknown';
      }

      this.$swal({
        title: this.lang('t_internalCall') + ` (${caller_name})`,
        cancelButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center"></i>`,
        confirmButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center cRotate"></i>`,
        customClass: {
          confirmButton: 'btn btn-danger rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
          cancelButton: 'btn btn-success rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
        },
        showClass: {
          popup: 'animate__animated animate__tada',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        allowEnterKey: false,
        showCancelButton: true,
      }).then(result => {
        console.log("openInternalCallModal", result)
        globalThis.LayoutContentRendererDefault.phone_ring_stop();
        if (result.value) { // REJECTED
          globalThis.LayoutContentRendererDefault.internal_session.terminate();
          globalThis._voyce.$qm.emit('qmgr:Unpause', {});

        } else if (result.dismiss === 'cancel') { // ACCEPTED
          var callOptions = {
            mediaConstraints: {
              audio: true,
              video: true
            }
          };
          globalThis.LayoutContentRendererDefault.internal_session.answer(callOptions);

          globalThis.LayoutContentRendererDefault.internal_session.connection.addEventListener('addstream', (e) => {
            var audio = document.createElement('audio');
            audio.srcObject = e.stream;
            audio.play();
          })
        }
      });
    },
    updateDots() {
      const currentLength = this.dots.length;
      if (currentLength < this.maxDots) {
        this.dots += '.';
      } else {
        this.dots = '';
      }
    },
    async onNewChatClick() {
      await globalThis.v_navbar.get_live_agent();
      this.panels.new_chat = true;
    },
    getQueueName() {
      let tmp_queue = this.current_call_info.Extra.Direction == 'outbound'
        ? this.current_call_info.Extra.Queue
        : this.current_call_info.Queue;
      // let tmp_queue = '';
      // if (this.current_call_info && this.current_call_info.Extra.Queue) {
      //   tmp_queue = this.current_call_info.Extra.Queue;
      // } else {
      //   tmp_queue = this.current_call_info.Queue;
      // }

      let _queue = queues.find(e => e.internal_name == tmp_queue);
      // console.log("QUEUE NAME", _queue)
      if (_queue) {
        return _queue.display_name;
      } else {
        return tmp_queue;
      }

    },
    onSelectedQueueChange(item) {
      this.selected_queue = item;
      this.selected_dial_plan = this.classifications.find((e) => e.queue == this.selected_queue).name ?? '';
      this.is_international_call = this.classifications.find((e) => e.queue == this.selected_queue).international_call ?? false;

    },
    formatTime(seconds) {
      seconds = Number(seconds);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    isNewDay(messages, index) {
      if (index === 0) {
        return true; // İlk mesaj her zaman yeni bir gün olarak kabul edilir
      } else {
        const currentMessage = new Date(messages[index].time);
        const previousMessage = new Date(messages[index - 1].time);
        return (
          currentMessage.getDate() !== previousMessage.getDate() ||
          currentMessage.getMonth() !== previousMessage.getMonth() ||
          currentMessage.getFullYear() !== previousMessage.getFullYear()
        );
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("tr-TR", {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    getConversationLetter2(chat) {

      let _user = chat.nickname.split("@")[0];
      _user = _user.split('.');
      let letter1 = _user[0][0];
      let letter2 = _user[_user.length - 1][0];
      return (letter1 + letter2);
    },
    getConversationLetter(chat) {

      let _user = this.username != chat._id.messages[0].From
        ? chat._id.messages[0].From.split("@")[0]
        : chat._id.messages[0].To.split("@")[0]
      _user = _user.split('.');
      let letter1 = _user[0][0];
      let letter2 = _user[_user.length - 1][0];
      return (letter1 + letter2);
    },
    getChatLetter() {
      let _user = this.active_chat.username.includes("@") ? this.active_chat.username.split("@")[0] : this.active_chat.username;
      _user = _user.split('.');
      let letter1 = _user[0][0];
      let letter2 = _user[_user.length - 1][0];
      return (letter1 + letter2);
    },
    handleToggle() {
      if (!this.isInputFocused) {
        this.phone_panel_toggle = false;
      }
    },
    onPhonePanelHover() {
      this.buttonStyle.backgroundColor = window.localStorage.getItem('vuexy-skin') == 'dark' ? '#353a4d' : '#f9f9f9';
      this.phone_panel_toggle = true;
    },
    getFinishCodeCategoryName(category_id) {
      let fc_category = globalThis.finish_categories.find(e => e._id == category_id);
      if (fc_category) {
        if (fc_category.category == 'Başarılı') {
          return 'Success';
        }
        return fc_category.category
      } else {
        return '';
      }
    },

    set_survey_state() {
      globalThis._voyce.$qm.emit('qmgr:MarkInteractionForSurvey', {
        interactionId: this.current_call_info.InteractionID,
      });

      let result = this.dtmf_list.find(e => e.includes("Anket"));
      console.log("result", result)

      if (result) {
        if (result.includes("Evet")) {
          result = result.replace("Evet", "Hayır");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Anket İptal Edildi.',
              icon: 'BellIcon',
            },
          })
        } else {
          result = result.replace(result.split("=")[1], "Evet");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Anket Onaylandı.',
              icon: 'BellIcon',
            },
          })

        }
      }
      let index = this.dtmf_list.findIndex(e => e.includes("Anket"));
      console.log("index", index);
      this.dtmf_list.splice(index, 1, result);
      // console.log("result",  = result;)
    },
    async SetIvrLineGroup(lg, ivr) {
      var response = (
        await this.$http_in.put(`/agent/v1/IVR/${ivr}`, { LineGroup: lg })
      ).data;
    },

    async transfer_interaction(internal_name) {
      let _self = this;
      if (this.interaction[this.selected_line] != null) {
        if (this.interaction[this.selected_line].isOnHold().local) {
          this.interaction[this.selected_line].unhold();
        }
      }

      if (this.selected_transfer_tab == 'External') {

      } else if (this.selected_transfer_tab == 'vIVR') {
        globalThis._voyce.$qm.emit('qmgr:StartVIVR', {
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name,
        });
      }
      else if (this.selected_transfer_tab == 'pbIVR') {

        let lg_name = '';
        if (this.selected_dial_plan != '') {
          lg_name = this.line_groups.find(e => e.internal_name == this.dial_plans.find(j => j.queue == this.selected_queue && j.classification == this.selected_dial_plan).line_group);

        } else {
          lg_name = this.line_groups.find(e => e.internal_name == this.dial_plans.find(j => j.queue == this.selected_queue).line_group);
        }

        await this.SetIvrLineGroup(lg_name, internal_name);

        globalThis._voyce.$qm.emit('qmgr:SendToIVROrQueue', {
          target_type: 'IVR',
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name,
          transferNote: this.transfer_note
        });
      }
      else if (this.selected_transfer_tab == 'Agent') {
        globalThis._voyce.$qm.emit('qmgr:TransferInteraction', {
          target_type: this.selected_transfer_tab,
          interactionId: this.current_call_info.InteractionID,
          toWhom: internal_name,
          transferNote: this.transfer_note
        });
      }
      else {
        globalThis._voyce.$qm.emit('qmgr:SendToIVROrQueue', {
          target_type: this.selected_transfer_tab,
          interactionId: this.current_call_info.InteractionID,
          internalName: internal_name,
          transferNote: this.transfer_note
        });
        // setTimeout(() => {
        //   _self.hangup();
        // }, 500);
      }

      this.modal_transfer_list = false;


    },
    transfer_tab_changed() {
      // console.log(item);
      // this.selected_transfer_tab = item.internal_name;
      this.selected_transfer_target = '';
      switch (this.selected_transfer_tab) {
        case 'External':

          break;
        case 'Agent':
          globalThis.v_navbar.get_live_agent();
          this.agent_list = globalThis.v_navbar.agent_list;
          break;
        default:
          this.transfer_list = this.transfer_targets[this.selected_transfer_tab];
          break;
      }
    },
    // preventNav(event) {
    //   if (globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] != null) {
    //     globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
    //       queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
    //       interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
    //     });

    //     globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
    //       queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue,
    //       interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
    //       verdict: 'İşlem Yapılmadı',
    //       details: {
    //         note: 'Çağrıda esnasında Voyce sayfası kapatıldı',
    //         action_date: '',
    //         last_finish_code: false,
    //         color: '',
    //         is_click_to_call: globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call ? globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call : false,
    //         finalize_reason: ''
    //       },
    //     });
    //   }
    //   event.preventDefault()
    //   event.returnValue = ""
    // },
    async doCopy(text) {
      await navigator.clipboard.writeText(text);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Metin kopyalandı.',
          icon: 'BellIcon',
        },
      })
    },
    getChatColor(chat) {
      return this.online_users.find(e => e.Status != 'Offline' &&
        `${e.Username}@${e.Domain}` == (globalThis.username != chat._id.messages[0].From
          ? chat._id.messages[0].From
          : chat._id.messages[0].To)
      ) != undefined
        ? 'light-success'
        : 'light-secondary'
    },

    open_transfer_modal() {
      globalThis.v_navbar.get_live_agent();
      this.agent_list = globalThis.v_navbar.agent_list;
      this.selected_transfer_target = '';
      this.transfer_note = '';
      this.modal_transfer_list = true;
    },
    on_finish_code_change() {
      if (this.other_permission.includes('set_is_last_finish_code')) {
        this.interaction_finish_code.is_last_finish_code = false;
      }
    },
    on_finish_code_change_copy() {
      if (this.other_permission.includes('set_is_last_finish_code')) {
        this.interaction_finish_code_copy.is_last_finish_code = false;
      }
    },
    phone_ring_start() {
      // console.log("phone_ring_start");
      audio.play();
    },
    phone_ring_stop() {
      // console.log("phone_ring_stop");
      audio.pause();
      audio.currentTime = 0;
    },
    accept_interaction() {
      console.log(this.current_call_info);
      globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
      });
      this.modal_incoming = false;
    },
    reject_interaction() {
      globalThis._voyce.$qm.emit('qmgr:RejectInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
        // reason: 'Not Answering',
      });
    },
    transfer_accept_interaction() {
      console.log(this.current_call_info);
      globalThis._voyce.$qm.emit('qmgr:AcceptTransfer', {
        interactionId: this.current_call_info.InteractionID,
      });
      this.modal_transfer = false;
    },
    transfer_reject_interaction() {
      globalThis._voyce.$qm.emit('qmgr:RejectTransfer', {
        interactionId: this.current_call_info.InteractionID,
        // reason: 'Not Answering',
      });
      this.modal_transfer = false;
      globalThis.LayoutContentRendererDefault.$swal.close();
      this.phone_ring_stop();

    },
    extra_time_ok() {
      clearInterval(this.extra_time_interval);
      globalThis._voyce.$qm.emit('qmgr:Unpause', {});
    },

    save_finish_code(bvModalEvent) {
      if (this.interaction_finish_code == '') {

        this.$bvToast.toast(`Sonuç kodu boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }
      clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
      if (globalThis.LayoutContentRendererDefault.hold_interval) {
        clearInterval(globalThis.LayoutContentRendererDefault.hold_interval);
        globalThis.LayoutContentRendererDefault.hold_interval = null;
      }


      if ((this.interaction_finish_code.require_date ?? true) && this.interaction_action_date == '') {

        this.$bvToast.toast(`Aksiyon tarihi boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }


      globalThis.statisticts.ch_data.push({
        interaction_id: this.current_call_info.InteractionID,
        insert_date: new Date(new Date().getTime() + 1000 * 60 * 60 * 3).toISOString().slice(11, 16),
        contact_number: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber((this.current_call_info.Extra?.InternationalCall == 'national' ? this.current_call_info.Extra?.RemoteNumber.slice(-10) : this.current_call_info.Extra?.RemoteNumber)) : (this.current_call_info.Extra?.InternationalCall == 'national' ? this.current_call_info.Extra?.RemoteNumber.slice(-10) : this.current_call_info.Extra?.RemoteNumber),
        finish_code: this.interaction_finish_code.finish_code,
        queuename: this.current_call_info.Extra.Direction == 'outbound' ? this.current_call_info.Extra.Queue : this.current_call_info.Queue,
        call_direction: this.current_call_info.Extra.Direction,
        note: this.interaction_note || '',
        ConnectedLength: 0,
        ACWLength: 0,
        RecordingFile: globalThis.v_navbar.other_permission.includes(
          "agent_display_sound"
        )
          ? this.current_call_info.Extra.RecordingFile
          : "",


      });
      localStorage.setItem("agent_statisticts", JSON.stringify(globalThis.statisticts.$data));

      // globalThis.statisticts.get_ch();



      // console.log("interaction_finish_code", this.interaction_finish_code);
      if (this.interaction_finish_code.is_extra_time) {
        this.modal_extra_time = true;
        // this.$store.commit("app/updateUserState", this.status.find(e => e._id == this.interaction_finish_code.extra_time_status).display_name);
        this.extra_time_counter = Number(this.interaction_finish_code.extra_time_amount) || 0;
        this.extra_time_interval = setInterval(() => {
          this.extra_time_counter--;
          if (this.extra_time_counter == 0) {
            this.modal_extra_time = false;
            clearInterval(this.extra_time_interval);
          }
        }, 1000);
      }


      globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
        queue: this.current_call_info.Queue,
        interactionId: this.current_call_info.InteractionID,
        verdict: this.interaction_finish_code.finish_code,
        hold_time: this.hold_time,
        details: {
          note: this.interaction_note,
          action_date: this.interaction_action_date,
          last_finish_code: this.interaction_last_finish_code,
          finish_code: { ...this.interaction_finish_code, extra_time_status_name: (this.interaction_finish_code.extra_time_status == '' ? '' : this.status.find(e => e._id == this.interaction_finish_code.extra_time_status).display_name) },
          color: this.interaction_color,
          is_click_to_call: this.current_call_info.is_click_to_call ? this.current_call_info.is_click_to_call : false,
          finalize_reason: this.after_call_break ? this.after_call_break_status : '',
          category_status: this.getFinishCodeCategoryName(this.interaction_finish_code.category_id)
        },
        raw: this.current_call_info
      });


      this.interaction_action_date_copy = '';
      this.interaction_finish_code_copy = '';
      this.interaction_color_copy = 'primary';
      this.interaction_note_copy = '';
      this.finish_code_owner = '';
      this.finish_code_owner_copy = '';
      this.after_call_break = false;
      this.after_call_break_status = '';

      this.current_call_info = null;
      this.show_real_time_transcript = false;

      if (this.interaction_finish_code.save_calendar) {
        setTimeout(() => {
          globalThis.LayoutContentRendererDefault.getEvents();
        }, 10000);
      }
      this.modal_finish_code = false;



    },
    get_queues: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Queue`)
      ).data;
      this.queues = [];
      this.queues.push({ internal_name: 'undefined', display_name: globalThis._lang('t_undefined') });

      this.queues = [...this.queues, ...response];
      //
    },
    GetTransferTargets: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/TransferTargets`)
      ).data;

      let _queues = [];
      let p_queues = globalThis.permissions["queue"] ?? [];
      for (const item of response.Queues) {
        if (p_queues.includes(item.internal_name)) {
          _queues.push(item);
        }
      }
      let _ivr = [];
      let _vivr = [];
      let p_ivr = globalThis.permissions["ivr"] ?? [];
      for (const item of [...response.Ivr, ...response.V_Ivr]) {
        if (p_ivr.includes(item.internal_name)) {
          if (!item.internal_name.includes('vivr')) {
            _ivr.push(item);
          } else {
            _vivr.push(item);
          }
        }
      }
      let _phone_book = [];
      let p_phone_book = globalThis.permissions["phone_book"] ?? [];
      for (const item of response.PB_Ivr) {
        if (p_phone_book.includes(item.internal_name)) {
          _phone_book.push(item);
        }
      }

      this.transfer_targets = {
        Queue: _queues,
        Agent: response.LocalUsers,
        IVR: _ivr,
        vIVR: _vivr,
        pbIVR: _phone_book,
      };
      this.line_groups = response.LineGroups;
    },
    GetStatus: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Status`)
      ).data;

      this.status = response.status;
    },

    get_dial_plans: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/DialPlan`)
      ).data;

      this.dial_plans = response;
      //
    },
    get_classifications: async function () {
      try {
        var response = (
          await this.$http_in.get(`agent/v1/Classification`)
        ).data;
        this.classifications = [];
        this.classifications_id_list = globalThis.permissions['dial_plan'];
        for (const item of response) {
          if (this.classifications_id_list.includes(item._id)) {
            this.classifications.push(item);
          }
        }



        // this.classification_queues = [...new Set([this.classifications.map(e => e.queue)])];
        // console.log("classification_queues", this.classification_queues);

        this.classification_queues = Array.from(new Set(this.classifications.map(e => e.queue)));
        // this.selected_queue = this.classification_queues[0];

        this.selected_queue = this.classification_queues.length > 0 ? this.classification_queues[0] : globalThis.user.selected_queues[0];
        this.onSelectedQueueChange(this.selected_queue);
        // console.log("classification_queues", this.classification_queues);
        // this.selected_dial_plan = this.classifications.length > 0 ? (this.classifications[0].name || '') : '';
      } catch (error) {
        console.log(error);
      }
    },
    write_phone(value) {
      if (this.interaction[this.selected_line] != null) {
        this.contact_number += value;
        this.dtmf_number += value;
        clearTimeout(this.dtmf_controller);
        this.dtmf_controller = setTimeout(() => {
          this.interaction[this.selected_line].sendDTMF(this.dtmf_number);
          this.dtmf_number = '';
        }, 1500);
      } else {
        this.contact_number += value;
      }
      this.$refs.contact_number.focus();
    },
    change_line(_line) {
      // console.log("_line", _line);
      // console.log("this.selected_line", this.selected_line);
      if (this.selected_line == _line) {
        return;
      }
      console.log("_line", _line);

      let old_line = _line == 2 ? 1 : 2;
      let new_line = _line;
      console.log("old_line", old_line);
      console.log("new_line", new_line);
      if (this.interaction[1] == null && new_line == 2) {
        this.prev_status = v_navbar.selected_status;
        globalThis._voyce.$qm.emit('qmgr:Pause', {
          reason: 'Second Line',
        });
      }
      if (this.interaction[1] == null && new_line == 1) {
        if (this.prev_status) {
          if (this.prev_status.display_name == 'Available') {
            globalThis._voyce.$qm.emit('qmgr:Unpause', {});
          } else {
            globalThis._voyce.$qm.emit('qmgr:Pause', {
              reason: this.prev_status.display_name,
            });
          }
          this.prev_status = null;
        }

      }
      this.selected_line = new_line;
      if (this.interaction[old_line] != null && !this.interaction[old_line].isOnHold().local) {
        // console.log("this.interaction[old_line]", this.interaction[old_line]._remote_identity._uri._user);
        this.interaction[old_line].hold();
      }
      // if (this.interaction[new_line] != null && this.interaction[new_line].isOnHold().local) {
      //   // console.log("this.interaction[old_line]", this.interaction[old_line]._remote_identity._uri._user);
      //   this.interaction[new_line].unhold();
      // }

      this.contact_number = '';
      if (this.interaction[new_line] != null) {
        this.contact_number = this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(this.interaction[new_line]._remote_identity._uri._user.slice(-10)) : this.interaction[new_line]._remote_identity._uri._user.slice(-10);
      }
    },
    call_request() {
      if (globalThis.v_navbar.other_permission.includes('verify_call')) {
        this.$swal({
          title: this.queues.find((e) => e.internal_name == this.selected_queue).display_name + " - " + this.selected_dial_plan,
          text: `[${this.selected_dial_plan}] hattından çıkış yapılacak, onaylıyor musunuz?`,
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          allowEnterKey: false,
          showCancelButton: true,
          // confirmButtonText: `<i class="bi bi-check"></i>`,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.call();
          }
        })
      }
      else {
        this.call();
      }
    },
    openIncomingModal() {
      console.log("openIncomingModal")

      this.phone_ring_start();
      let variable1 = `${this.current_call_info.InteractionType != "Text"
        ? this.other_permission.includes("mask_remote_number")
          ? maskPhoneNumber(
            this.current_call_info.Extra.RemoteNumber.substr(-11)
          )
          : this.current_call_info.Extra.RemoteNumber.substr(-11)
        : this.other_permission.includes("mask_remote_number")
          ? maskPhoneNumber(this.current_call_info.Extra.RemoteNumber)
          : this.current_call_info.Extra.RemoteNumber
        }`;
      let _q_name = this.getQueueName();
      console.log("_q_name", _q_name)

      let variable2 = _q_name + (this.current_call_info.Extra.CallerIDName
        ? " | " + this.current_call_info.Extra.CallerIDName
        : "");

      let variable3 = "";
      if (this.current_call_info.Extra.IvrVariables && this.current_call_info.Extra.IvrVariables.length > 0) {
        for (const item of this.current_call_info.Extra.IvrVariables) {
          if (item[0] == 'Customer') {
            variable3 = item[1];
          }
        }

      }

      let title = this.baseText;
      console.log("variable2", variable2)
      //  <span class="d-block mt-50 font-medium-2 text-center">
      //              ${title}
      //           </span>

      this.$swal({
        title: title,
        html: `<div class="mb-2">
        <div
          class="d-inline-block justify-content-center align-items-center text-center"
        >
         
          <span class="d-block mt-50 font-large-1 font-weight-bolder text-center">
            ${variable1}
          </span>
          <div class="d-flex align-items-center justify-content-center mt-50">
            <span class="d-block text-center">
             ${variable2}
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center mt-50">
            <span class="d-block text-center">
             ${variable3}
            </span>
          </div>
        </div>
      </div>`,

        cancelButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center"></i>`,
        confirmButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center cRotate"></i>`,
        customClass: {
          confirmButton: 'btn btn-danger rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
          cancelButton: 'btn btn-success rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
        },
        showClass: {
          popup: 'animate__animated animate__tada',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        allowEnterKey: false,
        showCancelButton: true,
      }).then(result => {
        if (result.value) { // CANCELED
          this.phone_ring_stop();
          this.reject_interaction();
        } else if (result.dismiss === 'cancel') { // ANSWERED
          this.phone_ring_stop();
          this.accept_interaction();

        }
      });

    },
    openTransferModal() {
      this.phone_ring_start();
      let variable1 =
        `${this.current_call_info.Extra.RemoteNumber.substr(-11)}`
        ;
      let variable2 = this.current_call_info.Extra.CallerIDName
        ? this.current_call_info.Extra.CallerIDName
        : "";

      this.$swal({
        title: this.lang('t_transfer') + ` (${this.current_call_info.From})`,
        html: `<div class="mb-2">
        <div
          class="d-inline-block justify-content-center align-items-center text-center"
        >
         
          <span class="d-block mt-50 font-large-1 font-weight-bolder text-center">
            ${variable1}
          </span>
          <div class="d-flex align-items-center justify-content-center mt-50">
            <span class="d-block text-center">
             ${variable2}
            </span>
          </div>
        </div>
      </div>`,

        cancelButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center"></i>`,
        confirmButtonText: `<i class="bi bi-telephone-fill font-medium-4 text-center cRotate"></i>`,
        customClass: {
          confirmButton: 'btn btn-danger rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
          cancelButton: 'btn btn-success rounded-circle cButtonSize text-center d-flex align-items-center justify-content-center mx-3',
        },
        showClass: {
          popup: 'animate__animated animate__tada',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        allowEnterKey: false,
        showCancelButton: true,
      }).then(result => {
        if (result.value) { // REJECTED
          this.phone_ring_stop();
          this.transfer_reject_interaction();
          this.modal_transfer = false;
        } else if (result.dismiss === 'cancel') { // ACCEPTED
          this.phone_ring_stop();
          this.transfer_accept_interaction();
          this.modal_transfer = false;
        }
      });

    },
    preview_call_request(phone, contact_id = '') {
      if (globalThis.v_navbar.other_permission.includes('verify_call')) {
        this.$swal({
          title: this.queues.find((e) => e.internal_name == this.selected_queue).display_name + " - " + this.selected_dial_plan,
          text: `[${this.selected_dial_plan}] hattından çıkış yapılacak, onaylıyor musunuz?`,
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.preview_call(phone, contact_id);
          }
        })
      }
      else {
        this.preview_call(phone, contact_id);
      }
    },
    call() {
      console.log(this.contact_number);

      if (this.selected_queue == '' || this.selected_dial_plan == '') {
        this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectQueueAndClassification")}`, {
          title: `${globalThis._lang("t_warning")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        });
        return;
      }
      let _QueueName = globalThis.queues.find(e => e.internal_name == this.selected_queue).display_name ?? 'undefined';
      console.log(`X-RemoteNumber: ${this.contact_number}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-QueueName: ${_QueueName}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`);


      if (this.contact_number.length > 0) {
        if (this.interaction[this.selected_line] == null) {
          this.contact_number = this.contact_number.split(' ').join('').replace(/\D+/g, '');

          if (this.selected_line == 2) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = globalThis.vSip.Call(`second_line_${this.contact_number}`, [`X-Domain: ${globalThis.user.domain}`, `X-Agent: ${globalThis.user.username}`, `X-Queue: ${this.selected_queue}`, `X-QueueName: ${_QueueName}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
          }
          else {
            globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;
            this.interaction[this.selected_line] = globalThis.vSip.Call(this.contact_number, [`X-RemoteNumber: ${this.contact_number}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-QueueName: ${_QueueName}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
          }

        }
      }



      // globalThis._voyce.$qm.emit('qmgr:StartOutboundInteraction', {
      //   Queue: this.selected_queue,
      //   Classification: this.selected_dial_plan,
      //   RemoteNumber: this.contact_number.slice(-10),
      // });

      // globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = {
      //   isMuted() {
      //     return {
      //       audio: false
      //     }
      //   },
      //   isOnHold() { return { local: false } },
      //   terminate() { return; }
      // };

      // globalThis.outboundcallsuccesstimer = setTimeout(() => {
      //   //
      //   globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
      //   globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
      //     queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue,
      //     interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
      //   });
      // }, 10000)
    },
    preview_call(phone, contact_id = '') {
      if (this.selected_queue == '' || this.selected_dial_plan == '') {
        this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectQueueAndClassification")}`, {
          title: `${globalThis._lang("t_warning")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        });
        return;
      }
      if (phone.length > 0) {
        let _QueueName = globalThis.queues.find(e => e.internal_name == this.selected_queue).display_name ?? 'undefined';
        if (this.interaction[this.selected_line] == null) {
          if (this.selected_line == 2) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = globalThis.vSip.Call(`second_line_${phone.split(' ').join('').replace(/\D+/g, '')}`, [`X-Domain: ${globalThis.user.domain}`, `X-Agent: ${globalThis.user.username}`, `X-Queue: ${this.selected_queue}`, `X-QueueName: ${_QueueName}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
          }
          else {
            globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;
            this.interaction[this.selected_line] = globalThis.vSip.Call(phone.split(' ').join('').replace(/\D+/g, ''), [`X-RemoteNumber: ${phone.split(' ').join('').replace(/\D+/g, '')}`, `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-QueueName: ${_QueueName}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`, `X-ContactID: ${contact_id}`]);
          }

          if (globalThis.v_navbar.other_permission.includes("mask_remote_number")) {
            this.contact_number = globalThis.maskPhoneNumber(phone.split(' ').join('').replace(/\D+/g, ''));
          } else {
            this.contact_number = phone.split(' ').join('').replace(/\D+/g, '');
          }

        }
      }


      // if (this.interaction[this.selected_line] == null) {
      //   this.contact_number = phone.split(' ').join('').replace(/\D+/g, '');

      //   globalThis.LayoutContentRendererDefault.old_call_status = globalThis.v_navbar.selected_status.display_name;

      //   this.interaction[this.selected_line] = globalThis.vSip.Call(phone, [`X-RemoteNumber: ${phone}`,  `X-Agent: ${globalThis.user.username}`, `X-Domain: ${globalThis.user.domain}`, `X-Queue: ${this.selected_queue}`, `X-Project: ${globalThis.user.selected_project}`, `X-Classification: ${this.selected_dial_plan}`]);
      // }
    },
    hangup() {
      // console.log("this.selected_line", this.selected_line);
      try {
        this.interaction[this.selected_line].terminate();
        this.interaction[this.selected_line] = null;
        if (this.selected_line == 2) {
          this.contact_number = '';
        }
      } catch (error) {
        this.interaction[this.selected_line] = null;
        this.contact_number = '';
      }

    },
    mute() {
      if (this.interaction[this.selected_line].isMuted().audio) {
        this.interaction[this.selected_line].unmute({ audio: true });
      }
      else {
        this.interaction[this.selected_line].mute({ audio: true });
      }
    },
    hold() {
      if (this.interaction[this.selected_line] != null) {
        if (!this.interaction[this.selected_line].isOnHold().local) {
          this.interaction[this.selected_line].hold();
        }
        else {
          this.interaction[this.selected_line].unhold();
        }
        if (this.interaction[1] != null) {
          if (this.interaction[1].isOnHold().local) {
            this.hold_interval = setInterval(() => {
              globalThis.LayoutContentRendererDefault.hold_time++;
            }, 1000);
          } else {
            if (this.hold_interval) {
              clearInterval(this.hold_interval);
              this.hold_interval = null;
            }

          }
        }
      }
    },
    showAnnounceDetails(item) {
      this.$swal({
        title: item.sender.includes("@")
          ? item.sender.split("@")[0] + " " + this.msgTime(item.announcements.Timestamp)
          : item.sender + " " + this.msgTime(item.announcements.Timestamp),
        html: item.announcements.msg,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      })
    },
    change_panel(type) {
      if (type == 'softphone') {
        this.chat_panel_toggle = false;

        if (this.phone_panel_toggle) {
          this.phone_panel_toggle = false;
        }
        else {
          this.phone_panel_toggle = true;
        }
      }
      else {
        this.phone_panel_toggle = false;
        if (this.chat_panel_toggle) {
          this.chat_panel_toggle = false;
        }
        else {
          this.chat_panel_toggle = true;
        }
      }
      // console.log(type);
    },
    // msgTime(UNIX_timestamp) {
    //   var a = new Date(UNIX_timestamp);
    //   var year = a.getFullYear();
    //   var month = a.getMonth();
    //   var date = a.getDate();
    //   var hour = a.getHours();
    //   var min = a.getMinutes();
    //   var time = date + "." + month + "." + year + " " + hour + ":" + min;
    //   return time;
    // },
    msToTimeString(ms) {
      let seconds = (ms / 1000) % 60;
      let minutes = Math.floor(ms / 1000 / 60) % 60;
      let hours = Math.floor(ms / 1000 / 60 / 60);

      minutes += hours * 60;
      seconds = ('0' + seconds).slice(-2);
      minutes = minutes > 99 ? minutes : ('0' + minutes).slice(-2);
      hours = ('0' + hours).slice(-2);

      return `${minutes}:${seconds}`;
    },
    showAnonsmentDetail(sender, time, msg) {
      this.anonsment_detail = true;
      this.anonsment_detail_content = {
        sender: sender,
        time: this.msgTime(time),
        msg: msg,
      };
    },
    closeChatPanel() {
      if (this.chatpanel == true) {
        this.chatpanel = false;
        this.active_chat = {
          username: "",
          user_from: "",
          user_to: "",
          messages: [],
        };
      } else {
        this.chatpanel = true;
      }
      // this.chatpanel = !this.chatpanel;
    },
    msgTime(t) {
      let date_ob = new Date(t);
      // var day = ("0" + date_ob.getDate()).slice(-2);
      // var month = ("0" + date_ob.getMonth() +1).slice(-2);
      // var year = date_ob.getFullYear();

      // var hours = ("0" + date_ob.getHours()).slice(-2);
      // var minutes = ("0" + date_ob.getMinutes()).slice(-2);

      // return `${day}.${month}.${year} ${hours}:${minutes}`;
      return new Date(date_ob.getTime() + (3 * 60 * 60 * 1000)).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2');
    },

    closeEmoji() {
      this.emoji_open = true;
      this.emoji_open = false;
    },
    selectedEmoji(args) {
      this.message = this.message + args.emoji;
      this.$refs.msg.focus();
    },
    scrollToBottom: () => {

      var scr = document.querySelector("#msg_div");
      if (![null, undefined].includes(scr)) {
        scr.scrollTop = scr.scrollHeight;
      }
    },
    GetFinishCodes: async function () {
      let tmp_FinishCodesByQueue = new Map();

      var response = (
        await this.$http_in.get(`agent/v1/FinishCodes`)
      ).data;
      // this.finish_codes = response.finish_codes;

      // for (const queue of globalThis.user.selected_queues) {
      //   let _queue = this.queues.find(e => e.internal_name == queue);
      //   if (_queue.finish_codes && _queue.finish_codes.length > 0) {
      //     // for (const finish_code of _queue.finish_codes) {
      //     // }
      //     tmp_FinishCodesByQueue.set(queue, _queue.finish_codes);

      //   }
      //   else {
      //     let fc = globalThis.permissions["finish_code"]
      //     let tmp_array = [];

      //     for (const row of response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code)) {
      //       // console.log(row);
      //       if (fc.includes(row['finish_code'])) {
      //         tmp_array.push(row['finish_code']);
      //       }
      //       tmp_FinishCodesByQueue.set(queue, tmp_array);

      //     }
      //   }
      // }

      // console.log("tmp_FinishCodesByQueue", tmp_FinishCodesByQueue);
      // for (const __queue of tmp_FinishCodesByQueue.keys()) {
      //   console.log("__queue", __queue);
      //   let c_fc = [];
      //   for (const __fc of tmp_FinishCodesByQueue.get(__queue)) {
      //     let o_fc = response.finish_codes.find(e => e.finish_code == __fc);
      //     if (o_fc) {
      //       c_fc.push(o_fc);
      //     }
      //   }
      //   this.FinishCodesByQueue.set(__queue, c_fc);

      // }

      // console.log(Array.from(calculated_fc));
      let fc = globalThis.permissions["finish_code"]
      // console.log("this.$store.state.privileges", this.$store.state.privileges);
      for (const row of response.finish_codes.filter(e => !e.is_product_finish_code && !e.is_system && !e.is_chat_finish_code)) {
        // console.log(row);
        if (fc.includes(row['finish_code'])) {
          this.finish_codes.push(row);
        }
      }

      // for (const _fc of Array.from(calculated_fc)) {
      //   let _tmp = response.finish_codes.find(e => e.finish_code == _fc)
      //   let _cond = this.finish_codes.find(e => e.finish_code == _fc)
      //   if (!_cond && _tmp) {
      //     this.finish_codes.push(_tmp);
      //   }
      // }
      // this.interaction_finish_code = this.finish_codes[0];

      // console.log("this.finish_codes", this.finish_codes);

      // this.finish_codes = response.finish_codes;
      globalThis.finish_codes = response.finish_codes;
      globalThis.finish_categories = response.finish_categories;
    },
    startNewChat: async function (nickname) {
      console.log("nickname", nickname);
      this.active_chat.messages = [];
      this.active_chat.username = nickname;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = nickname;
      this.chatpanel = true;
      this.panels.new_chat = false;
      let increaseCouter = this.userUnRead[nickname]
        ? this.userUnRead[nickname]
        : 0;
      this.totalUnRead = this.totalUnRead - increaseCouter;
      this.userUnRead[nickname] = null;
      this.userUnRead = { ...this.userUnRead };
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { sender: nickname });
      globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", { nickname });

      // await this.socket.emit("GetUnreadFrom", nickname);
      // await this.socket.emit("AllUsersMessageList", nickname);
      // this.$refs.msg.focus();
      //MESAJLAR BURADA ÇEKİLECEK MANUEL BOŞALTIYORUM ŞUAN İÇİNİ
      this.active_chat.messages = [];
      this.message = "";
    },
    continueNewChat: async function (chat) {
      // console.log("nickname",nickname);
      var nickname = globalThis.username != chat._id.messages[0].From ? chat._id.messages[0].From : chat._id.messages[0].To;
      console.log("nickname", nickname);
      this.active_chat.messages = [];
      this.active_chat.username = nickname;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = nickname;
      this.chatpanel = true;
      this.panels.new_chat = false;
      let increaseCouter = this.userUnRead[nickname]
        ? this.userUnRead[nickname]
        : 0;
      this.totalUnRead = this.totalUnRead - increaseCouter;
      this.userUnRead[nickname] = null;
      this.userUnRead = { ...this.userUnRead };
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { nickname });
      globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", { nickname });

      // await this.socket.emit("GetUnreadFrom", nickname);
      // await this.socket.emit("AllUsersMessageList", nickname);
      // this.$refs.msg.focus();
      //MESAJLAR BURADA ÇEKİLECEK MANUEL BOŞALTIYORUM ŞUAN İÇİNİ
      this.active_chat.messages = [];
      this.message = "";
      setTimeout(() => {
        globalThis.LayoutContentRendererDefault.$refs.msg.focus();
      }, 300);

    },

    sendMsg() {
      if (
        this.message.trim() == "" ||
        this.active_chat.user_to == "" ||
        this.active_chat.user_from == ""
      ) {
        this.message = "";
        this.$refs.msg.focus();
      } else {
        globalThis._voyce.$qm.emit("Chat:ChatMessage", {
          target: this.active_chat.user_to,
          message: this.message,
        });

        this.active_chat.messages.push({
          to: this.active_chat.user_to,
          from: this.active_chat.user_from,
          message: this.message,
          time: new Date().getTime(),
        });
        this.message = "";
        this.$refs.msg.focus();
        setTimeout(() => {
          globalThis.LayoutContentRendererDefault.scrollToBottom();
        }, 150);
      }
    },


    getEvents: async function () {
      var response = (
        await this.$http_in.get(`/agent/v1/Calendar/Today`)
      ).data;
      this.events = [];
      // const merge_events = [].concat(response[0], response[1]);
      const merge_events = response;
      for (const item of merge_events) {
        this.events.push({
          id: item._id,
          title: item.finishcode,
          start: item.start,
          end: item.end,
          note: item.note,
          contact_number: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(item.contact_number) : item.contact_number,
          color: item.color,
        });
      }
    },
    async showCalendarPopup(item) {



      var response = (
        await this.$http_in.get(`/crm/v1/CustomersByPhone/${item.contact_number.slice(-10)}`)
      ).data;


      this.$toast.success({
        component: CalendarNotification,
        props: {
          verdict: `${item.title} (${item.start.split(' ')[1]})`,
          remoteNumber: this.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(item.contact_number.slice(-10)) : item.contact_number.slice(-10),
          note: item.note,
          customer_name: response.result.length > 0 ? (response.result[0].hasOwnProperty('customer_name') ? response.result[0]["customer_name"] : '') : '',
          customer_no: response.result.length > 0 ? (response.result[0].hasOwnProperty('customer_no') ? response.result[0]["customer_no"] : '') : '',
        },
        listeners: {
          myClick: () => { this.preview_call_request(item.contact_number.slice(-10)); }
        }
      }, {
        onClose: () => {
          console.log('Toast message has been closed.');
          this.toast_limit--;
          this.getEvents();
        },
        position: "bottom-left",
        timeout: 30000000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.76,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        closeButtonClassName: "close-button",
        toastClassName: "toast-property",
        maxToasts: 3,
        // icon: {
        //   iconClass: "",
        //   iconChildren: "",
        //   iconTag: "i"
        // },
        rtl: false
      });
    },
    setEvents: async function (item) {
      var response = (
        await this.$http_in.put(`/agent/v1/Calendar/Status`, { EventInfo: item })
      ).data;
    },
  },
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav)
  // },

  mounted: async function () {

    globalThis.LayoutContentRendererDefault = this;
    this.setAgentList();
    this.interval_agent_list = setInterval(() => {
      if (this.modal_agent_list) {
        this.setAgentList();
      }
    }, 1000);

    this.interval_conference = setInterval(() => {
      if (this.conference_modal) {
        this.conference_data = [...this.conference_data];
      }
    }, 1000);

    window.addEventListener('keydown', this.handleKeydown);
    this.other_permission = globalThis.permissions["other"] || [];

    if (this.other_permission.includes("agent_list_details")) {
      this.agent_list_fields.push({ key: 'Productivity', label: globalThis._lang('t_productivity'), sortable: true });
      this.agent_list_fields.push({ key: 'AcceptedCalls', label: globalThis._lang('t_answered'), sortable: true });
      this.agent_list_fields.push({ key: 'MissedCalls', label: globalThis._lang('t_rInboundFail'), sortable: true });
      this.agent_list_fields.push({ key: 'SuccessfulOutboundCalls', label: globalThis._lang('t_successfullCall'), sortable: true });
      this.agent_list_fields.push({ key: 'FailedOutboundcalls', label: globalThis._lang('t_unsuccessfullCall'), sortable: true });
    }
    this.agent_list_fields.push({ key: 'actions', label: '', sortable: false, thStyle: { width: '2vh' } });




    window.addEventListener('focus', () => {
      // console.log("focus");
      globalThis.LayoutContentRendererDefault.is_window_active = true;
    });
    window.addEventListener('blur', () => {
      // console.log('blue');
      globalThis.LayoutContentRendererDefault.is_window_active = false;
    });

    this.username = globalThis.username;
    this.ring_audio = audio;
    await this.get_queues();

    await this.GetFinishCodes();
    await this.get_dial_plans();
    await this.get_classifications();
    await this.GetStatus();
    await this.getEvents();
    await this.GetTransferTargets();


    // Promise.all([this.GetFinishCodes()
    //   , this.get_queues()
    //   , this.get_classifications()
    //   , this.get_dial_plans()
    //   , this.GetStatus()
    // ]);


    this.transfer_interval = setInterval(() => {
      if (globalThis.v_navbar && globalThis.LayoutContentRendererDefault.modal_transfer_list && globalThis.LayoutContentRendererDefault.selected_transfer_tab == 'Agent') {
        globalThis.LayoutContentRendererDefault.agent_list = globalThis.v_navbar.agent_list;
      }
    }, 1000);
    this.calendarInterval = setInterval(() => {
      for (const item of this.events) {
        const _date = new Date();
        // console.log("CC", (_date - new Date(item.start)) / 1000 / 60);
        if ((new Date(item.start) - _date) / 1000 / 60 <= 5) {

          if (this.toast_limit > 2) {
            continue;
          }
          globalThis.LayoutContentRendererDefault.toast_limit++;

          globalThis.LayoutContentRendererDefault.showCalendarPopup(item);
          globalThis.LayoutContentRendererDefault.setEvents(item);
          globalThis.LayoutContentRendererDefault.events = globalThis.LayoutContentRendererDefault.events.filter(e => e != item);
        }
      }
    }, 5000);

    // if (globalThis.v_navbar && !globalThis.v_navbar.is_registered) {
    //   globalThis.v_navbar.register_change();

    // }
    // globalThis.username = 'ersever@voyce';


    // console.log("globalThis._voyce.$qm", globalThis._voyce.$qm);


    globalThis.qm_status_watcher.on("connected", () => {
      console.log("qm_socket_connected", "connected")
      globalThis.LayoutContentRendererDefault.qm_socket_connected = true;
    })
    globalThis.qm_status_watcher.on("disconnect", () => {
      console.log("qm_socket_connected", "disconnect")
      globalThis.LayoutContentRendererDefault.qm_socket_connected = false;
    })


    globalThis.vSip.RegisterHandlers({
      'progress': function (e) {
        console.log('call is in progressaaaa OUTBOUND');
      },
      'failed': function (e) {
        console.log('call failed with cause3:  OUTBOUND', e);
        if (globalThis.LayoutContentRendererDefault.old_call_status == 'Available') {
          globalThis._voyce.$qm.emit('qmgr:Unpause', {});

        }
        else {
          globalThis._voyce.$qm.emit('qmgr:Pause', {
            reason: globalThis.LayoutContentRendererDefault.old_call_status,
          });
        }

        globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
        globalThis.LayoutContentRendererDefault.contact_number = '';

      },
      'ended': function (e) {
        try {
          console.log("ended", "the call has ended", e);

          clearInterval(globalThis.LayoutContentRendererDefault.interval);
          globalThis.LayoutContentRendererDefault.call_timer = 0;

          if (globalThis.session && globalThis.session.isEnded()) {
            globalThis.LayoutContentRendererDefault.interaction[2] = null;
            globalThis.session = null;
            globalThis.LayoutContentRendererDefault.conference_data = [];

            return;
          } else {
            if (globalThis.LayoutContentRendererDefault.interaction[2]) {
              globalThis.LayoutContentRendererDefault.interaction[2].terminate();
            }
          }

          hangup.play();
          audio.pause();
          audio.currentTime = 0;

          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          globalThis.LayoutContentRendererDefault.conference_data = [];

          globalThis.LayoutContentRendererDefault.contact_number = '';

          clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
          clearInterval(globalThis.LayoutContentRendererDefault.interval);

          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw ?? 10;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";
          globalThis.LayoutContentRendererDefault.after_call_break = false;
          globalThis.LayoutContentRendererDefault.after_call_break_status = '';

          // Blacklist sonrası Available geçiyor diye kaldırıldı.
          // globalThis._voyce.$qm.emit('qmgr:Unpause', {});



        } catch (error) {
          console.log(error);
        }


        hangup.play();
        globalThis.LayoutContentRendererDefault.dtmf_list = [];
        globalThis.LayoutContentRendererDefault.modal_dtmf_list = false;
        globalThis.LayoutContentRendererDefault.tmp_dtmf_string = [];
        if (globalThis.LayoutContentRendererDefault.current_call_info) {
          globalThis.LayoutContentRendererDefault.contact_number = '';

          clearInterval(globalThis.LayoutContentRendererDefault.interval);

          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";
          globalThis.LayoutContentRendererDefault.after_call_break = false;
          globalThis.LayoutContentRendererDefault.after_call_break_status = '';
          globalThis.LayoutContentRendererDefault.modal_finish_code = true;
          globalThis.LayoutContentRendererDefault.interaction_action_date = globalThis.LayoutContentRendererDefault.interaction_action_date_copy;
          globalThis.LayoutContentRendererDefault.interaction_finish_code = globalThis.LayoutContentRendererDefault.interaction_finish_code_copy;
          globalThis.LayoutContentRendererDefault.interaction_color = globalThis.LayoutContentRendererDefault.interaction_color_copy;
          globalThis.LayoutContentRendererDefault.interaction_note = globalThis.LayoutContentRendererDefault.interaction_note_copy;
          // console.log("ACW TIMEOUT", globalThis.LayoutContentRendererDefault.acw_timer);
          console.log("5", "setInterval");

          globalThis.LayoutContentRendererDefault.acw_interval = setInterval(() => {
            globalThis.LayoutContentRendererDefault.acw_timer--;
            if (globalThis.LayoutContentRendererDefault.acw_timer <= 0) {
              console.log("5", "clearInterval");

              clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);

              globalThis.LayoutContentRendererDefault.modal_finish_code = false;
              // globalThis.LayoutContentRendererDefault.current_call_info = null;
              globalThis.statisticts.get_ch();
            }
          }, 1000);

          globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
            queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
            interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
          });
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
        } else {
          globalThis.LayoutContentRendererDefault.contact_number = '';
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;

          if (globalThis.LayoutContentRendererDefault.old_call_status == 'Available') {
            globalThis._voyce.$qm.emit('qmgr:Unpause', {});

          }
          else {
            globalThis._voyce.$qm.emit('qmgr:Pause', {
              reason: globalThis.LayoutContentRendererDefault.old_call_status,
            });
          }
        }

        globalThis.LayoutContentRendererDefault.selected_line = 1;
      },
      'confirmed': function (e) {
        console.log('call confirmed OUTBOUND');
        if (globalThis.LayoutContentRendererDefault.selected_line == 1) {

          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.interval = setInterval(() => {
            globalThis.LayoutContentRendererDefault.call_timer += 1000;
            globalThis.LayoutContentRendererDefault.call_timer_text = globalThis.LayoutContentRendererDefault.msToTimeString(globalThis.LayoutContentRendererDefault.call_timer);
          }, 1000);
        }



      },
      'peerconnection': function (e) {
        console.log("peerconnection", this.selected_line);
        console.log("peerconnection", e);
        if (globalThis.LayoutContentRendererDefault.selected_line != 2) {
          globalThis._voyce.$qm.emit('qmgr:Pause', {
            reason: 'Calling',
          });
        }
      },

      'connecting': function (e) {
        console.log(globalThis.LayoutContentRendererDefault.old_call_status);
        console.log('call accepted connecting');

      },
      'accepted': function (e) {
        console.log('call accepted OUTBOUND');
      },
    });
    globalThis.user_agent.on("newMessage", (e) => {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: globalThis._lang('t_notification'),
      //     icon: 'PhoneIncomingIcon',
      //     text: globalThis._lang('t_callConnectedBack'),
      //     variant: 'info',
      //   },
      // },
      //   {
      //     position: 'bottom-right',
      //     timeout: 3000,
      //     // toastClassName: "toast-property-warning",
      //   });
      console.log("newMessage", e);
      let _self = this;
      if ([null, undefined, ''].includes(e.request.body)) {
        e.request.body = e.request.headers.Body[0].raw;
      }
      if (e.request.body.startsWith("vivr:")) {
        console.log(`newMessage && e.request.body`, e.request.body);
        if (e.request.body.includes('##')) {
          for (const item of e.request.body.split(":")[1].split('##')) {
            _self.dtmf_list.push(item);
          }
        } else {
          _self.dtmf_list.push(e.request.body.split(":")[1]);
        }
        // console.log("this.dtmf_list", this.dtmf_list);
      }
      else if (e.request.body.startsWith("internal_call:")) {
        globalThis._voyce.$qm.emit('qmgr:Pause', {
          reason: 'In Call - Internal',
        });
      }

      else if (e.request.body.startsWith("conference_call_accepted")) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: globalThis._lang('t_conference'),
            icon: 'bi-exclamation-circle',
            text: globalThis._lang('t_conferenceCallAccepted'),
            variant: 'info',
          },
        },
          {
            position: 'top-center',
            // timeout: 36000000,
            // toastClassName: "toast-property-warning",
          });
        conference_dial_audio.pause();
        conference_dial_audio.currentTime = 0;
        conference_accepted_audio.play();

        let tmp_obj = this.conference_data.find(j => j.RemoteNumber == e.request.body.split(':')[1]);
        if (tmp_obj) {
          tmp_obj.Channel = e.request.body.split(':')[2];
          tmp_obj.Status = 'Accepted';
          tmp_obj.Time = new Date().getTime();
        }


      }
      else if (e.request.body.startsWith("conference_call_hangup")) {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: globalThis._lang('t_conference'),
            icon: 'bi-exclamation-circle',
            text: globalThis._lang('t_conferenceCallHangup'),
            variant: 'danger',
          },
        },
          {
            position: 'top-center',
            // timeout: 36000000,
            // toastClassName: "toast-property-warning",
          });

        this.conference_data = this.conference_data.filter(j => j.RemoteNumber != e.request.body.split(':')[1]);



      }
      else if (e.request.body.startsWith("conference_call_declined")) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: globalThis._lang('t_conference'),
            icon: 'bi-exclamation-circle',
            text: globalThis._lang('t_conferenceCallDeclined'),
            variant: 'warning',
          },
        },
          {
            position: 'top-center',
            // timeout: 36000000,
            // toastClassName: "toast-property-warning",
          });
        conference_dial_audio.pause();
        conference_dial_audio.currentTime = 0;
        conference_declined_audio.play();
        // let tmp_obj = this.conference_data.find(j => j.RemoteNumber == e.request.body.split(':')[1]);
        // if (tmp_obj) {
        //   tmp_obj.Channel = e.request.body.split(':')[2];
        //   tmp_obj.Status = 'Declined';
        // }
        this.conference_data = this.conference_data.filter(j => j.RemoteNumber != e.request.body.split(':')[1]);

      }



      //  if (e.request.body.startsWith("dtmf:")) {
      //   // console.log(`Gotten DTMF, key: ${e.request.body.split(':')[1]}, channel:${e.request.from.uri.user}`);
      //   clearTimeout(this.dtmf_interval);
      //   this.dtmf_interval = setTimeout(() => {
      //     if (_self.tmp_dtmf_string.length > 0) {
      //       _self.dtmf_list.push(_self.tmp_dtmf_string.join(''));
      //       _self.tmp_dtmf_string = [];
      //     }
      //     clearTimeout(_self.dtmf_interval);

      //   }, 4000);

      //   if (e.request.body.split(':')[1] == '#') {
      //     if (_self.tmp_dtmf_string.length > 0) {
      //       _self.dtmf_list.push(_self.tmp_dtmf_string.join(''));
      //       _self.tmp_dtmf_string = [];
      //     }
      //     clearTimeout(this.dtmf_interval);
      //   }
      //   else if (e.request.body.split(':')[1] == '*') {
      //     this.tmp_dtmf_string = [];
      //   }
      //   else {
      //     _self.tmp_dtmf_string.push(e.request.body.split(':')[1])
      //   }
      //  }

    });
    globalThis.user_agent.on("newRTCSession", function (e) {
      console.log("session", e.session);
      var session = e.session; //




      if (globalThis.LayoutContentRendererDefault.selected_line == 2 && globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] == null) {
        globalThis.session = session;
      } var callOptions = {
        mediaConstraints: {
          audio: true,
          video: true
        }
      };
      globalThis.LayoutContentRendererDefault.interaction_finish_code = '';
      globalThis.LayoutContentRendererDefault.interaction_action_date = '';
      globalThis.LayoutContentRendererDefault.interaction_color = 'primary';
      globalThis.LayoutContentRendererDefault.interaction_note = '';
      clearTimeout(globalThis.outboundcallsuccesstimer);

      // console.log("USER AGENT", globalThis.user_agent);
      if (session._direction != 'outgoing') {
        session.on("accepted", function (e) {
          // the call has answered
          // console.log("accepted CALL", e);

          // console.log("accepted", "the call has answered", e);


        });
        session.on("confirmed", function (e) {



          console.log("ibound_confirmed", e);
          clearTimeout(globalThis.LayoutContentRendererDefault.channelInterval);
          audio.pause();
          audio.currentTime = 0;
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = e.ack.ua._sessions[e.ack.call_id + e.ack.from_tag];

          globalThis.LayoutContentRendererDefault.contact_number = globalThis.LayoutContentRendererDefault.other_permission.includes('mask_remote_number') ? globalThis.maskPhoneNumber(globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line]._remote_identity._uri._user.slice(-10)) : globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line]._remote_identity._uri._user;
          // globalThis.LayoutContentRendererDefault.$store.commit("app/updateUserState", "Çağrıda Gelen");
          if (globalThis.LayoutContentRendererDefault.selected_line == 1) {
            globalThis.LayoutContentRendererDefault.call_timer = 0;
            globalThis.LayoutContentRendererDefault.interval = setInterval(() => {
              globalThis.LayoutContentRendererDefault.call_timer += 1000;
              globalThis.LayoutContentRendererDefault.call_timer_text = globalThis.LayoutContentRendererDefault.msToTimeString(globalThis.LayoutContentRendererDefault.call_timer);
            }, 1000);
          }

        });


        console.log("e.request.headers", e.request.headers);
        if (e.request.headers.hasOwnProperty("X-Call-Type")) {

          // const val = globalThis.statuses.find(e => e.display_name == globalThis.v_navbar.selected_status.display_name);
          if (globalThis.v_navbar.selected_status.display_name.includes('In Call')) {
            // let _category = globalThis.status_categories.find(e => e._id == val.status_category_id);
            // console.log("_category", _category);
            // if (_category && _category.display_name == 'In Call') {
            session.terminate();
            return;
            // }
          }

          globalThis._voyce.$qm.emit('qmgr:Pause', {
            reason: 'In Call - Internal',
          });

          globalThis.LayoutContentRendererDefault.internal_session = e.session;
          globalThis.LayoutContentRendererDefault.phone_ring_start();
          globalThis.LayoutContentRendererDefault.openInternalCallModal();
        } else {
          session.answer(callOptions);
          session.connection.addEventListener('addstream', (e) => {
            var audio = document.createElement('audio');
            audio.srcObject = e.stream;
            audio.play();
          })
        }


        session.on("ended", function (e) {
          console.log("ended", e);
          try {
            if (globalThis.session && globalThis.session.isEnded()) {
              globalThis.LayoutContentRendererDefault.interaction[2] = null;
              globalThis.session = null;
              globalThis.LayoutContentRendererDefault.conference_data = [];
              return;
            } else {
              if (globalThis.LayoutContentRendererDefault.interaction[2]) {
                globalThis.LayoutContentRendererDefault.interaction[2].terminate();
              }
            }
          } catch (error) {
            console.log(error)
          }
          // Cannot read property 'Queue' of null
          globalThis.LayoutContentRendererDefault.dtmf_list = [];
          globalThis.LayoutContentRendererDefault.modal_dtmf_list = false;
          globalThis.LayoutContentRendererDefault.tmp_dtmf_string = [];
          console.log("globalThis.LayoutContentRendererDefault.current_call_info", globalThis.LayoutContentRendererDefault.current_call_info);
          console.log("globalThis.v_navbar.selected_status.display_name", globalThis.v_navbar.selected_status.display_name);

          if (globalThis.LayoutContentRendererDefault.current_call_info && globalThis.v_navbar.selected_status.display_name != 'In Call - Internal') {
            globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
              queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
              interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID,
            });

            hangup.play();

            audio.pause();
            audio.currentTime = 0;
            // console.log("ended", "the call has ended");

            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
            globalThis.LayoutContentRendererDefault.conference_data = [];

            globalThis.LayoutContentRendererDefault.contact_number = '';

            clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
            clearInterval(globalThis.LayoutContentRendererDefault.interval);

            globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw ?? 10;
            globalThis.LayoutContentRendererDefault.call_timer = 0;
            globalThis.LayoutContentRendererDefault.last_call_event = "";
            globalThis.LayoutContentRendererDefault.after_call_break = false;
            globalThis.LayoutContentRendererDefault.after_call_break_status = '';
            globalThis.LayoutContentRendererDefault.modal_finish_code = true;
            globalThis.LayoutContentRendererDefault.interaction_action_date = globalThis.LayoutContentRendererDefault.interaction_action_date_copy;
            globalThis.LayoutContentRendererDefault.interaction_finish_code = globalThis.LayoutContentRendererDefault.interaction_finish_code_copy;
            globalThis.LayoutContentRendererDefault.interaction_color = globalThis.LayoutContentRendererDefault.interaction_color_copy;
            globalThis.LayoutContentRendererDefault.interaction_note = globalThis.LayoutContentRendererDefault.interaction_note_copy;
            // console.log("ACW TIMEOUT", globalThis.LayoutContentRendererDefault.acw_timer);
            console.log("3", "setInterval");

            globalThis.LayoutContentRendererDefault.acw_interval = setInterval(() => {
              globalThis.LayoutContentRendererDefault.acw_timer--;
              if (globalThis.LayoutContentRendererDefault.acw_timer <= 0) {
                // Zaman aşımı girilecek...
                console.log("4", "clearInterval");

                clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
                globalThis.LayoutContentRendererDefault.modal_finish_code = false;
              }
            }, 1000);

            globalThis.LayoutContentRendererDefault.selected_line = 1;

          } else {
            hangup.play();

            audio.pause();
            audio.currentTime = 0;
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;

            globalThis.LayoutContentRendererDefault.contact_number = '';

            clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);
            clearInterval(globalThis.LayoutContentRendererDefault.interval);
            globalThis.LayoutContentRendererDefault.call_timer = 0;
            globalThis.LayoutContentRendererDefault.last_call_event = "";
            globalThis.LayoutContentRendererDefault.after_call_break = false;
            globalThis.LayoutContentRendererDefault.after_call_break_status = '';
            console.log("qmgr:Unpause2");

            globalThis._voyce.$qm.emit('qmgr:Unpause', {});
          }



        });
        session.on("failed", async function (e) {
          console.log("failed unable to establish the call", e);
          audio.pause();
          audio.currentTime = 0;
          globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          globalThis.LayoutContentRendererDefault.contact_number = '';
          globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
          globalThis.LayoutContentRendererDefault.call_timer = 0;
          globalThis.LayoutContentRendererDefault.last_call_event = "";
          if (globalThis.LayoutContentRendererDefault.internal_session) {
            // globalThis.LayoutContentRendererDefault.internal_session.terminate();
            globalThis._voyce.$qm.emit('qmgr:Unpause', {});
            globalThis.LayoutContentRendererDefault.$swal.close();
          }

          if (globalThis.LayoutContentRendererDefault.current_call_info) {

            globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
              queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || 'undefined',
              interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID || '',
            });

            await setTimeout(async () => { }, 1000);

            globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
              queue: globalThis.LayoutContentRendererDefault.current_call_info.Queue || '',
              interactionId: globalThis.LayoutContentRendererDefault.current_call_info.InteractionID || '',
              verdict: 'RemoteConnectionLost',
              details: {
                note: 'Bağlantı Hatası',
                action_date: '',
                last_finish_code: false,
                color: '',
                is_click_to_call: false,
                finalize_reason: '',
                category_status: '',
                error: 'failed unable to establish the call'
              },
            });


          }




          // unable to establish the call
        });



        // let resp = session.terminate();
        // console.log("sessioon", session);

      }


    })
    // WEBHOOK

    globalThis._voyce.$qm.on("WebhookTrigger", async (data) => {
      console.log("WebhookTrigger", data);
      window.open(data.Url, '_blank');
    });
    globalThis._voyce.$qm.on("WebhookNotification", async (data) => {
      if (data.Action == 't_appointmentAssignment') {
        this.getEvents();
      }
      console.log("WebhookNotification", data);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: globalThis._lang(data.Action),
          icon: 'BellIcon',
          text: globalThis._lang(data.Message),
          variant: data.Variant,
        },
      },
        {
          position: data.Position,
          timeout: 36000000,
          // toastClassName: "toast-property-warning",
        });
      var audio = new Audio("sounds/voyce_notification_01.wav");
      audio.play();


      this.AddNotification({
        Title: globalThis._lang(data.Action),
        Text: globalThis._lang(data.Message),
        Icon: 'bi-bell',
        Variant: data.Variant ?? 'primary',
        Read: false,
        Time: this.getTimeInfo()
      });
    });

    // EXTERNAL CHAT
    globalThis._voyce.$qm.on("ExternalChatMessageCreated", async (data) => {
      console.log("ExternalChatMessageCreated", data);
      if (globalThis.external_chat && globalThis.external_chat.conversation) {
        globalThis.external_chat.conversation.history.push(data.payload);
        globalThis.external_chat.get_conversation_details(globalThis.external_chat.conversation);
      }
    });
    globalThis._voyce.$qm.on("ExternalChatConversationCreated", async (data) => {
      console.log("ExternalChatConversationCreated", data);
      if (globalThis.external_chat) {
        globalThis.external_chat.get_conversations();
      }
    });


    // INTERNAL CHAT
    globalThis._voyce.$qm.on("OfflineMessages", async (data) => {
      console.log("offlineMessages", data);
      console.log("recentdata", this.recent);

      for (let val of data) {
        this.totalUnRead += val.count;
        globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", val._id);
        if (this.userUnRead.hasOwnProperty(val._id)) {
          this.userUnRead[val._id] += val.count;
          this.userUnRead = { ...this.userUnRead };
        } else {
          this.userUnRead[val._id] = val.count;
          this.userUnRead = { ...this.userUnRead };
        }
      }
    });

    globalThis._voyce.$qm.emit("Chat:ChatRegister", {
      username: globalThis.username
    });

    globalThis._voyce.$qm.emit("Chat:GetLastMessages", {
      username: globalThis.username,
    });

    globalThis._voyce.$qm.emit("Chat:GetAnonsmentHistory", {
      username: globalThis.username,
    });
    globalThis._voyce.$qm.on("AnonsmentHistoryResponse", (data) => {
      console.log("AnonsmentHistoryResponse", data);
      this.announcements = data.result;
    });
    globalThis._voyce.$qm.emit("Chat:GetSuffleHistory", {
      username: globalThis.username,
    });

    globalThis._voyce.$qm.on("LastMessagesResponse", (data) => {
      if (data.lastMessages.length > 0) {
        data.lastMessages = data.lastMessages.sort(function (x, y) {
          return y._id.messages[0].Timestamp - x._id.messages[0].Timestamp;
        });
      }

      this.recent = [...data.lastMessages];
      // console.log(this.recent);
    });


    globalThis._voyce.$qm.on("ChatMessagesError", (data) => {
      console.log("ChatMessagesError", data);
    });
    globalThis._voyce.$qm.on("AllUsersMessageListResponse", (data) => {
      for (let arg of data.topTenMessage.reverse()) {
        this.active_chat.messages.push({
          to: arg.messages.To,
          from: arg.messages.From,
          message: arg.messages.msg,
          time: arg.messages.Timestamp,
        });
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    });


    globalThis._voyce.$qm.on("Alert", async (data) => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Uyarı',
          // title: 'Uyarı' + ` (${data.Target})`,
          icon: 'bi-exclamation-circle',
          text: data.Message,
          variant: 'warning',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          // toastClassName: "toast-property-warning",
        });

      var audio = new Audio("sounds/voyce_notification_01.wav");
      audio.play();


      this.AddNotification({
        Title: `Uyarı`,
        Text: data.Message,
        Icon: 'bi-exclamation-circle',
        Variant: 'warning',
        Read: false,
        Time: this.getTimeInfo()
      });

    });

    globalThis._voyce.$qm.on("offlineMessages", (data) => {
      console.log("offlineMessages", data);
    });
    globalThis._voyce.$qm.on("ChatRegisterAdd", (data) => {
      console.log("ChatRegisterAdd", data);
    });

    globalThis._voyce.$qm.on("ChatMessageResponse", async (data) => {
      console.log("ChatMessageResponse", data);
      // this.active_chat.messages = [];
      // this.message = "";
      console.log(data);
      // if (LayoutContentRendererDefault.chatpanel == false) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Mesaj (${data.sender.split('@')[0]})`,
          icon: 'bi-chat',
          text: data.message.substr(0, 24),
          variant: 'primary',
        },
      },
        {
          position: 'bottom-right',
          // timeout: 36000000,
          // toastClassName: "toast-property",

        })
      // }

      globalThis._voyce.$qm.emit("Chat:GetLastMessages", {
        username: globalThis.username,
      });

      if (this.active_chat.user_to != data.sender) {
        this.totalUnRead += 1;
        if (this.userUnRead.hasOwnProperty(data.sender)) {
          this.userUnRead[data.sender] += 1;
          this.userUnRead = { ...this.userUnRead };
        } else {
          this.userUnRead[data.sender] = 1;
          this.userUnRead = { ...this.userUnRead };
        }
      }

      console.log("unread", this.userUnRead);

      this.active_chat.username = data.sender;
      this.active_chat.user_from = globalThis.username;
      this.active_chat.user_to = data.sender;
      globalThis._voyce.$qm.emit("Chat:GetUnreadFrom", { sender: data.sender });
      // globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", data.sender);
      if (this.active_chat.user_to == data.sender) {
        await this.active_chat.messages.push({
          to: globalThis.username,
          from: data.sender,
          message: data.message,
          time: new Date().getTime(),
        });
      }
      var audio = new Audio("sounds/voyce_notification_03.wav");
      audio.play();

      setTimeout(() => {
        globalThis.LayoutContentRendererDefault.scrollToBottom();
      }, 150);
    });
    globalThis._voyce.$qm.on("ChatMessageTargetError", (data) => {
      console.log("ChatMessageTargetError", data);
    });
    globalThis._voyce.$qm.emit("Chat:GetAllUsers", globalThis.username);
    globalThis._voyce.$qm.emit("Chat:AllUsersMessageList", globalThis.username);

    globalThis._voyce.$qm.on("AnnouncementMessageResponse", async (data) => {
      console.log("AnnouncementMessageResponse", data);
      if (data.target == 'user') {
        if (!data.users.includes(globalThis.user.identifier)) {
          return;
        }
      }
      else {
        if (globalThis.user.selected_project != data.project) {
          return;
        }
      }


      this.AddNotification({
        Title: `Anons (${data.sender.split('@')[0]})`,
        Text: data.message,
        Icon: 'bi-megaphone',
        Variant: 'primary',
        Read: false,
        Time: this.getTimeInfo()
      });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Anons (${data.sender.split('@')[0]})`,
          icon: 'bi-megaphone',
          text: data.message,
          variant: 'primary',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          // toastClassName: "toast-property",

        });
      let anons = [
        {
          announcements: {
            Timestamp: data.Timestamp,
            msg: data.message,
          },
          sender: data.sender,
        },
      ];

      this.announcements = [...anons, ...this.announcements];
      console.log(data);
      var audio = new Audio("sounds/voyce_notification_01.wav");
      audio.play();
    });
    globalThis._voyce.$qm.on("SouffleMessageResponse", async (data) => {

      this.AddNotification({
        Title: `Sufle (${data.sender.split('@')[0]})`,
        Text: data.message,
        Icon: 'bi-chat-quote',
        Variant: 'info',
        Read: false,
        Time: this.getTimeInfo()
      });

      var audio = new Audio("sounds/voyce_notification_01.wav");
      audio.play();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Sufle (${data.sender.split('@')[0]})`,
          icon: 'bi-chat-quote',
          text: data.message,
          variant: 'info',
        },
      },
        {
          position: 'top-center',
          timeout: 36000000,
          // toastClassName: "toast-property-warning",
        });
    });

    globalThis._voyce.$qm.on("RealtimeSpeechEvent", async (data) => {
      console.log("RealtimeSpeechEvent", data);
      if (globalThis.LayoutContentRendererDefault.current_call_info) {
        if (!globalThis.LayoutContentRendererDefault.current_call_info.speech) {
          globalThis.LayoutContentRendererDefault.current_call_info.speech = [];
        }

        globalThis.LayoutContentRendererDefault.current_call_info.speech.push({
          InteractionID: data.InteractionID,
          Side: data.Side,
          Text: data.Text,
          Time: new Date().getTime()
        });

        globalThis.LayoutContentRendererDefault.current_call_info = JSON.parse(JSON.stringify(globalThis.LayoutContentRendererDefault.current_call_info));
      }
      else {
        console.log("ELSE")
        if (globalThis.spv_dashboard && globalThis.spv_dashboard.selected_agent && globalThis.spv_dashboard.selected_agent.InteractionID == data.InteractionID) {
          console.log(globalThis.spv_dashboard.selected_agent.speech)

          if (!globalThis.spv_dashboard.selected_agent.speech) {
            globalThis.spv_dashboard.selected_agent.speech = [];
          }
          console.log(globalThis.spv_dashboard.selected_agent)

          globalThis.spv_dashboard.selected_agent.speech.push({
            InteractionID: data.InteractionID,
            Side: data.Side,
            Text: data.Text,
            Time: new Date().getTime()
          });
          console.log("speech", globalThis.spv_dashboard.selected_agent.speech);

          globalThis.spv_dashboard.selected_agent = JSON.parse(JSON.stringify(globalThis.spv_dashboard.selected_agent));

        }
      }
    });




    globalThis._voyce.$qm.on("GetAllUsersResponse", async (data) => {
      // this.chat_users = data.userList;
      console.log("chatusers,", this.chat_users)
    });

    globalThis._voyce.$qm.on("ChatRegisterResponse", (data) => {
      this.online_users = this.agent_list;
    });

    globalThis._voyce.$qm.on("GetUnreadFromResponse", (data) => {
      console.log("GetUnreadFromResponse", data);
    });

    if (globalThis.v_navbar) {
      this.agent_list = globalThis.v_navbar.agent_list;
    }


    // });

    globalThis.LayoutContentRendererDefault.qm_socket_connected = globalThis.v_navbar.qm_socket.connected;
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.handleKeydown);

    clearInterval(this.interval_conference);
    clearInterval(this.interval_agent_list);
    clearInterval(this.transfer_interval);
    clearInterval(this.calendarInterval);
    clearInterval(this.acw_interval);
    clearInterval(this.extra_time_interval);
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    globalThis._voyce.$qm.removeAllListeners();
    // globalThis._voyce.$qm.disconnect();


  },

  setup() {
    const { skin, routerTransition, contentWidth } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      routerTransition, contentWidth, isDark
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.time {
  display: block;
  text-align: right;
  font-size: 10px;
}

#chat {
  position: absolute;
  background: transparent;
  right: 12px;
  bottom: 0;
  width: 390px;
}

.userslist {
  height: 420px;
  overflow: auto;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newchatlist {
  max-height: 500px;
  width: 100%;
  overflow: auto;
}

.startchat:nth-child(even) {
  background-color: transparent;
}

.chat-panel-container {
  background: transparent;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 95%;
  z-index: 2;
}

.chat_panel_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.messages {
  background: transparent;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.input-area {
  border-top: 1px solid transparent;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-area input {
  width: 100%;
  height: 100%;
  outline: 0;
  background: $light;
  font-size: 1em;
  line-height: 1.05em;
  padding: 10px;
}

.msg {
  min-height: 40px;
  height: auto;
  padding: 10px 10px;
  margin-bottom: 10px;
  width: 100%;
}

.msg_to {
  background: $primary;
  color: white;
  float: right;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 20px 20px 0 20px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.msg_from {
  background: $secondary;
  color: white;
  float: left;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px 20px 20px 0;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.msg_note {
  background: $warning;
  color: white;
  float: right;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px 20px 20px 20px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 85%;
  display: block;
  clear: both;
}

.user-unread {
  background: linear-gradient(
    126deg,
    rgba(38, 70, 83, 1) 0%,
    rgba(70, 128, 152, 1) 100%
  );
  position: absolute;
  right: 15px;
  padding: 10px;
  font-size: 14px;
  width: 30px;
  height: 30px;
  color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-menu-class {
  max-height: 30vh;
  overflow-y: auto;
}

.test1 {
  bottom: -20;
  right: 0;
}

.zIndex {
  z-index: 9999;
}

.close-button {
  color: #82868b !important;
}
.toast-property {
  margin-top: "75px";
  border: 2px solid;
}
.toast-property-warning {
  border: 2px solid #ff9f43;
}
.full-height {
  height: 100vh;
}
.slide-in {
  transform: translateX(100%);

  animation: slideIn 0.5s forwards;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.fade-in-effect {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hover-effect:hover {
  background-color: #f8f8f8;
}
.hover-effect-dark:hover {
  background-color: #161d31;
}
.chat-bg {
  // background-image: url("chat_bg.jpg");
}
.date-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  flex-grow: 1;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}
.no-hover:hover,
.no-hover:focus,
.no-hover:active {
  background-color: initial; /* Reset background color */
  border-color: initial; /* Reset border color */
  box-shadow: none; /* Remove box shadow if any */
  /* Add any other properties you need to reset */
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.vs__dropdown-menu {
  z-index: 9999 !important;
}

.cZIndex {
  z-index: 9999 !important;
}

.cButtonSize {
  text-align: center !important;
  width: 4.5rem !important;
  height: 4.5rem !important;
}
.cRotate {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  display: inline-block;
}

.v-custom-control-primary
  .custom-control-input:checked
  ~ .custom-control-label::before,
.v-custom-control-primary
  .custom-control-input:not(:checked)
  ~ .custom-control-label::before {
  background-color: #7367f0;
  border-color: #7367f0;
}

/* Opsiyonel: Eğer switch'in handle'ı (kaydırıcı) da her zaman beyaz olmasını istiyorsanız */
.v-custom-control-primary
  .custom-control-input:checked
  ~ .custom-control-label::after,
.v-custom-control-primary
  .custom-control-input:not(:checked)
  ~ .custom-control-label::after {
  background-color: #fff; /* Beyaz renk */
}
</style>

